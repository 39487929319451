<template>
  <v-tooltip bottom :disabled="disabled">
    <template v-slot:activator="{ on }">
      <div
        v-resize="isEllipsisActive"
        :class="
          isMobile
            ? [attrs, 'text-truncate', 'mobile']
            : [attrs, 'text-truncate']
        "
        ref="truncate"
        v-on="on"
      >
        <slot></slot>
      </div>
    </template>
    <span v-if="title">{{ title }}</span>
    <slot v-else></slot>
  </v-tooltip>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    title: String,
    attrs: String,
    recalculate: Boolean,
  },
  data() {
    return {
      disabled: false,
    };
  },
  mounted() {
    this.$nextTick(() => this.isEllipsisActive());
  },
  methods: {
    isEllipsisActive() {
      let e = this.$refs["truncate"];

      if (e) {
        if (e.offsetWidth >= e.scrollWidth) {
          this.disabled = true;
        } else {
          this.disabled = false;
        }
      }
    },
  },
  watch: {
    recalculate() {
      if (this.recalculate) this.isEllipsisActive();
    },
  },
  computed: {
    ...mapGetters("settings", ["isMobile"]),
  },
};
</script>
<style scoped lang="scss">
.text-truncate.mobile {
  white-space: normal !important;
  word-wrap: break-word !important;
}
.subheading {
  font-size: 16px;
}
.bold {
  font-weight: bold;
}
</style>
