import store from "../store";
export function authHeader() {
  // return authorization header with jwt token
  let token = authToken();

  if (token) {
    return { Authorization: token };
  } else {
    return {};
  }
}

export function authToken() {
  let token = (store.state.oidcStore || {}).access_token;
  return `Bearer ${token}`;
}
