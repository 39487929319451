import Vue from "vue";
import "moment-duration-format";
const moment = require("moment");
moment.locale("pl");

const formats = {
  date: "YYYY-MM-DD",
  time: "HH:mm",
  timeWithSec: "HH:mm:ss",
  dateTime: "YYYY-MM-DD HH:mm",
  displayDate: "DD-MM-YYYY",
  displayDateTime: "DD-MM-YYYY HH:mm",
};

moment.emptyDate = moment([2000, 1, 1]);

moment.fn.format = new Proxy(moment.fn.format, {
  apply: function (target, thisArg, argumentsList) {
    if (Object.prototype.hasOwnProperty.call(formats, argumentsList[0])) {
      argumentsList[0] = formats[argumentsList[0]];
    }

    return target.apply(thisArg, argumentsList);
  },
});

moment.getDto = (obj) => (moment.isMoment(obj) ? obj.toJSON() : null);

moment.convertToQueryString = (value) => {
  if (!value || (moment.isMoment(value) && !value.isValid())) {
    return null;
  } else if (moment.isMoment(value) && value.isValid) {
    return moment(value).format("YYYY-MM-DDTHH:mm:ss");
  } else if (typeof value === "string") {
    return moment(value).format("YYYY-MM-DDTHH:mm:ss");
  }
};

moment.mergeDateTime = function (date, time) {
  if (!date || !time) {
    return null;
  }

  return moment()
    .set({
      year: date.get("year"),
      month: date.get("month"),
      date: date.get("date"),
      hour: time.get("hour"),
      minute: time.get("minute"),
      second: time.get("second"),
    })
    .milliseconds(0);
};

Vue.use(require("vue-moment"), {
  moment,
});

export default moment;
