import axios from "axios";
import { paramsSerializer } from "../plugins/http";
import { authToken } from "@/plugins/auth-header";
import WithUser from "../utils/WithUser";

const CancelToken = axios.CancelToken;
let cancel;
let reportCancel;
const apiUrl = "api/complaints";

export default {
  GetComplaints(params) {
    return axios.get(apiUrl, { params, paramsSerializer });
  },
  GetMyComplaints(params) {
    return axios.get(`${apiUrl}/own`, { params, paramsSerializer });
  },
  getCategoriesHierarchy(restrictionId) {
    return axios.get(`${apiUrl}/categoriesHierarchy/${restrictionId}`);
  },
  GetComplaintsStates() {
    return axios.get(`${apiUrl}/states`);
  },
  GetStatesForComplaintDetails() {
    return axios.get(`${apiUrl}/statesForDetails`);
  },
  GetComplaint(id) {
    return axios.get(`${apiUrl}/${id}`);
  },
  UpdateComplaint(model, complaintId) {
    const data = WithUser(model);
    const multipartOpt = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    return axios.put(`${apiUrl}/${complaintId}`, data, multipartOpt);
  },
  UpdateComplaintCard(model) {
    return axios.put(`${apiUrl}/${model.id}/card`, WithUser(model));
  },
  CreateComment(model) {
    return axios.post(`${apiUrl}/comments`, WithUser(model));
  },
  GetPriorities() {
    return axios.get(`${apiUrl}/priorities`);
  },
  GetComplaintDetailsList(ids) {
    return axios.get(`${apiUrl}/dynamicList`, {
      params: { ids },
      paramsSerializer,
    });
  },
  getAttachments(complaintId) {
    return axios.get(`${apiUrl}/${complaintId}/attachments`);
  },
  getAttachmentFile(id) {
    let fileType;

    return fetch(`${apiUrl}/attachments/${id}/download`, {
      method: "GET",
      headers: {
        Authorization: authToken(),
        Accept: "application/json",
        "Cache-Control": "no-cache",
      },
    })
      .then((response) => {
        fileType = response.headers.get("Content-Type");
        const reader = response.body.getReader();

        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      .then((stream) => new Response(stream))
      .then((response) => response.blob())
      .then((blob) => {
        return { type: fileType, file: blob };
      });
  },
  deleteAttachments(attachmentIds) {
    return axios.delete(`${apiUrl}/attachments`, {
      data: attachmentIds,
    });
  },
  GetSubCategoryOwner(subCategoryId) {
    return axios.get(`${apiUrl}/subCategories/${subCategoryId}/owner`);
  },
  GetCategoryOwners(categoryId) {
    return axios.get(`${apiUrl}/categories/${categoryId}/owners`);
  },
  GetComplaintsReport(params) {
    return axios.get(`${apiUrl}/report`, {
      timeout: 1800000, // 30 minut
      params,
      paramsSerializer,
      responseType: "blob",
      cancelToken: new CancelToken((x) => (reportCancel = x)),
    });
  },
  CancelGetComplaintsReport() {
    if (reportCancel !== undefined) reportCancel();
  },
  ChainComplaints(params) {
    return axios.post(`${apiUrl}/chainComplaints`, WithUser(params));
  },
  GetAttachmentRestrictions() {
    return axios.get(`${apiUrl}/attachmentRestrictions`);
  },
  GetAvailableComplaintsToChain(search) {
    let params = { search };
    if (cancel !== undefined) {
      cancel();
    }

    return axios.get(`${apiUrl}/availableToChain`, {
      params,
      cancelToken: new CancelToken((x) => (cancel = x)),
    });
  },
  changeComplaintStatuses(ids, newState) {
    return axios.post(
      `${apiUrl}/changeComplaintStatuses`,
      WithUser({ complaintIds: ids, state: newState })
    );
  },
  addAttachment(attachment, signal) {
    const form = new FormData();

    const blob = new Blob([attachment.attachBlob], {
      type: attachment.attachContentType,
    });
    const file = new File([blob], attachment.attachFullName, {
      type: attachment.attachContentType,
    });

    form.append("fileData", file);

    return axios.post(`${apiUrl}/attachment`, form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      signal: signal,
    });
  },
  checkAttachmentStatus(attachmentIds, signal) {
    return axios.post(`${apiUrl}/attachmentsStatus`, attachmentIds, {
      signal: signal,
    });
  },
  UpdateComplaintAttachments(complaintId, attachmentIds) {
    const data = {
      attachments: attachmentIds,
    };

    return axios.put(`${apiUrl}/${complaintId}/attachments`, WithUser(data));
  },
  SendReminderMessage(complaintId, message) {
    const data = WithUser({ message: message });

    return axios.post(`${apiUrl}/${complaintId}/reminderMessage`, data);
  },
  getFieldsForGroupComplaintStatusChange(data) {
    return axios.post(`${apiUrl}/details/fields`, data);
  },
  groupStatusChangeWithFields(data) {
    return axios.post(`${apiUrl}/details`, data);
  },
  sendComplaintToJira(complaintId) {
    return axios.post(`api/jira?complaintId=${complaintId}`);
  },
  getComplaintComments(complaintId) {
    return axios.get(`${apiUrl}/${complaintId}/comments`);
  },
};
