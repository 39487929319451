<template>
  <v-app-bar min-height="64" id="toolbar" :class="isMobile ? 'mobile' : null">
    <div id="home-button" v-if="!isMobile" @click="setDefault">
      <img id="logo" src="@/assets/logo.svg" alt="logo" />
    </div>

    <div v-if="isMobile">
      <HamburgerMenu />
    </div>
    <div id="title-header" :class="isMobile ? 'mobile' : null">
      {{ $t(`menu.${this.$route.name}`).toUpperCase() }}
    </div>

    <div id="toolbar-actions">
      <NotificationsBell id="notification-button" />
      <div id="user-info" v-if="!isMobile">
        <div class="full-name">{{ user.full_name }}</div>
        <div class="name">{{ user.name }}</div>
      </div>
      <UserSettings id="user-settings" v-if="!isMobile" />
    </div>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import NotificationsBell from "./BellNotifications/NotificationsBell";
import HamburgerMenu from "@/components/HamburgerMenu";
import UserSettings from "@/components/UserSettings";

export default {
  components: { NotificationsBell, UserSettings, HamburgerMenu },
  name: "MainToolbar",
  data() {
    return {};
  },
  props: {
    toggleDrawer: Function,
  },
  computed: {
    ...mapGetters("settings", ["isMobile"]),
    ...mapGetters("oidcStore", ["oidcUser"]),
    user() {
      return this.oidcUser || {};
    },
  },
  methods: {
    ...mapActions({
      setDefaultState: "setDefaultState",
    }),
    setDefault() {
      this.setDefaultState();
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style scoped lang="scss">
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.014), 0px 1px 10px 0px rgba(0, 0, 0, 0.012);
}

#toolbar {
  width: 100%;
  height: 64px;
  position: fixed;
  z-index: 2;
  padding-right: 24px;
  background-color: $main-toolbar;
  border-bottom: 5px solid $main-toolbar-border;

  &.mobile {
    padding-right: 0;
  }
}

#home-button {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  height: 59px;
  width: 60px;
  border-bottom-right-radius: 20px;
  box-shadow: 1px 1px 5px -2px rgba(66, 68, 90, 1);
  background-color: $home-button-background;

  ::v-deep.icon-wrapper {
    height: 100%;
    margin-left: 10px;
  }

  #logo {
    width: 60px;
    height: 60px;
  }
}

#title-header {
  color: $main-toolbar-text;
  font-size: 20px;
  font-weight: 500;
  margin-left: 60px;

  &.mobile {
    margin-left: 20px;
  }
}

#user-info {
  color: $main-toolbar-text;
  display: flex;
  flex-direction: column;
  margin: 5px 10px 0 20px;

  .full-name {
    text-align: right;
    white-space: nowrap;
  }
  .name {
    font-size: 13px;
    margin-left: auto;
    margin-top: -5px;
  }
}

#toolbar-actions {
  display: flex;
  margin-left: auto;
}
</style>
