import { EmitError } from "@/eventBus.js";
import i18n from "../plugins/i18n";
import axios from "axios";

export const EmitServerError = (err) => {
  if (axios.isCancel(err)) return;
  const data = GetErrorData(err);
  let errMsg = i18n.t("errors.DEFAULT");
  if (err.response && err.response.status === 403) {
    errMsg = i18n.t("errors.FORBID");
  }
  if (data && data.errorCode && i18n.te(`errors.${data.errorCode}`)) {
    errMsg = i18n.t(`errors.${data.errorCode}`);
  }
  if (data.errorDescription) {
    errMsg = data.errorDescription;
  }
  EmitError(errMsg);
  return errMsg;
};

export const GetErrorData = (err) => {
  if (err && err.response) {
    return err.response.data;
  }
  return undefined;
};

export const GetErrorMsg = (errCode) =>
  i18n.te(`errors.${errCode}`)
    ? i18n.t(`errors.${errCode}`)
    : i18n.t("errors.DEFAULT");

export default EmitServerError;
