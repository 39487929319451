import axios, { paramsSerializer } from "../plugins/http";
import WithUser from "../utils/WithUser";

const apiUrl = "api/users";

export default {
  GetUsers(params) {
    return axios.get(apiUrl, {
      params,
      paramsSerializer,
    });
  },
  GetAllUsers() {
    return axios.get(`${apiUrl}/all`);
  },
  Edit(model) {
    return axios.put(apiUrl, WithUser(model));
  },
  GetUserRolesWithCategories(id) {
    return axios.get(`${apiUrl}/rolesLCategories/${id}`);
  },
  GetLoggedUserProfile() {
    return axios.get(`${apiUrl}/me/profile`);
  },
  ChangePassword(userId, model) {
    return axios.post(`${apiUrl}/changePassword/${userId}`, model);
  },
  Deactivate(ids) {
    return axios.put(`${apiUrl}/deactivate`, ids);
  },
  ResetUserPassword(id) {
    return axios.post(`${apiUrl}/${id}/resetPassword`);
  },
  saveSettings(settings) {
    return axios.post(`${apiUrl}/saveSettings`, {
      settingsJson: JSON.stringify(settings),
    });
  },
  getSettings() {
    return axios.get(`${apiUrl}/getSettings`);
  },
  GetDeletedUsers() {
    return axios.get(`${apiUrl}/deleted`);
  },
  RestoreDeletedUser(user) {
    return axios.put(`${apiUrl}/deleted/${user.id}`, user);
  },
  async GetUsersSearch(searchValue, pageNumber = 1, pageSize = 200) {
    const response = await axios.get(
      `${apiUrl}/search?search=${searchValue}&pagenumber=${pageNumber}&pagesize=${pageSize}`
    );
    return response.data;
  },
};
