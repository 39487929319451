import Vue from "vue";
const EventBus = new Vue();
import { NotificationStatus } from "@/models/notificationParams";

export default EventBus;

export function EmitError(msg) {
  EventBus.$emit("eb-error", msg);
}

export function EmitWarning(msg, timeout) {
  EventBus.$emit("eb-warning", msg, timeout);
}

export function EmitSuccess(msg) {
  EventBus.$emit("eb-success", msg);
}

export function EmitInfo(msg) {
  EventBus.$emit("eb-info", msg);
}
export function EmitMessage(msg, status) {
  switch (status) {
    case NotificationStatus.SUCCESS:
      EmitSuccess(msg);
      break;

    case NotificationStatus.ERROR:
      EmitError(msg);
      break;

    case NotificationStatus.INFO:
      EmitInfo(msg);
      break;

    case NotificationStatus.WARNING:
    default:
      EmitWarning(msg);
      break;
  }
}
