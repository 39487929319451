<template>
  <v-layout wrap>
    <template v-for="(item, index) in values">
      <CategoryField
        textField
        class="xs10 my-2"
        :key="index"
        v-bind="$attrs"
        :edit="edit"
        :value="values[index][itemText]"
        @input="(value) => onValueChange(index, value)"
        :error-messages="errorMessages[index]"
        :label="getLabel(index)"
        v-on="listeners"
      >
        <slot :item="item[itemText]"></slot>
      </CategoryField>
      <template v-if="edit || displayFavoriteButtonWhenNoEdit">
        <v-flex
          :key="`${index}button`"
          xs2
          my-2
          v-if="index === 0 && !disabled"
        >
          <v-btn v-if="edit" icon text @click="addItem()">
            <IconBox color="fields" :tooltip="$t('newContact.addChannel')">
              stem-circlePlus
            </IconBox>
          </v-btn>
          <div v-if="displayFavoriteButton && values[index][itemText]">
            <v-btn
              v-if="values[index][favaritePropertyName]"
              icon
              text
              @click="() => onUnsetAsFavorite(index)"
            >
              <IconBox color="fields" :tooltip="unsetFavoriteTooltipText">
                stem-starFilled
              </IconBox>
            </v-btn>
            <v-btn
              v-else-if="edit"
              icon
              text
              @click="() => onSetAsFavorite(index)"
            >
              <IconBox color="fields" :tooltip="setFavoriteTooltipText">
                stem-star
              </IconBox>
            </v-btn>
          </div>
        </v-flex>
        <v-flex :key="`${index}button`" xs2 my-2 v-if="index !== 0">
          <v-btn v-if="edit" icon text @click="removeItem(index)">
            <IconBox color="fields" :tooltip="$t('newContact.deleteChannel')">
              stem-circleMinus
            </IconBox>
          </v-btn>
          <div v-if="displayFavoriteButton && values[index][itemText]">
            <v-btn
              v-if="values[index][favaritePropertyName]"
              icon
              text
              @click="() => onUnsetAsFavorite(index)"
            >
              <IconBox color="fields" :tooltip="unsetFavoriteTooltipText">
                stem-starFilled
              </IconBox>
            </v-btn>
            <v-btn
              v-else-if="edit"
              icon
              text
              @click="() => onSetAsFavorite(index)"
            >
              <IconBox color="fields" :tooltip="setFavoriteTooltipText">
                stem-star
              </IconBox>
            </v-btn>
          </div>
        </v-flex>
      </template>
    </template>
    <v-flex v-if="values.length === 0 && edit && !disabled" xs12 my-2>
      <v-btn icon text @click="addItem()">
        <IconBox color="fields" :tooltip="$t('newContact.addChannel')">
          stem-circlePlus
        </IconBox>
      </v-btn>
    </v-flex>
    <v-flex v-if="values.length === 0 && !edit && !disabled" xs12 my-2>
      <label class="label textColor--text">{{ this.label }}</label>
    </v-flex>
  </v-layout>
</template>

<script>
import CategoryField from "@/components/Fields/CategoryField";

export default {
  components: { CategoryField },
  model: {
    prop: "values",
    event: "input",
  },
  props: {
    values: {
      type: Array,
      required: true,
    },
    edit: Boolean,
    label: String,
    additionalLabel: String,
    newItem: Function,
    errorMessages: { type: Array, default: () => [] },
    itemText: { type: [String, Array, Function], default: "text" },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    displayFavoriteButton: { type: Boolean, default: false },
    favaritePropertyName: { type: String, default: "isFavorite" },
    setFavoriteTooltipText: { type: String },
    unsetFavoriteTooltipText: { type: String },
    displayFavoriteButtonWhenNoEdit: { type: Boolean },
  },
  computed: {
    listeners() {
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },
  },
  methods: {
    getLabel(index) {
      if (!this.label) return this.required ? "*" : "";
      if (index === 0 || this.additionalLabel === undefined) {
        return this.required ? `*   ${this.label}` : this.label;
      }
      return this.required
        ? `*    ${this.additionalLabel}`
        : this.additionalLabel;
    },
    addItem() {
      this.$emit("input", [...this.values, this.newItem()]);
    },
    removeItem(index) {
      const items = [...this.values];
      items.splice(index, 1);
      this.$emit("input", [...items]);
    },
    onValueChange(index, newValue) {
      const modifyItem = this.values[index];
      if (modifyItem) {
        modifyItem.text = newValue ? newValue.trim() : "";

        if (!newValue && modifyItem[this.favaritePropertyName]) {
          modifyItem[this.favaritePropertyName] = false;
        }
      }
      this.$emit("input", this.values);
    },
    onSetAsFavorite(index) {
      this.$emit("setAsFavorite", index);
    },
    onUnsetAsFavorite(index) {
      this.$emit("unsetAsFavorite", index);
    },
  },
};
</script>

<style scoped lang="scss">
.label {
  font-size: 13px;
}
</style>
