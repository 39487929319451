import axios from "../plugins/http";

const apiRootUrl = "api/notifications";
export default {
  deleteNotifications() {
    return axios.post(`${apiRootUrl}`);
  },
  deleteNotification(id) {
    return axios.post(`${apiRootUrl}/${id}`);
  },
  readNotifications() {
    return axios.put(`${apiRootUrl}/read`);
  },
  getNotificationsPaged(take, previousNotificationId) {
    return axios.get(`${apiRootUrl}`, {
      params: { take, previousNotificationId },
    });
  },
  markNotificationAsRead(id) {
    return axios.put(`${apiRootUrl}/${id}/StatusRead`);
  },
};
