<template>
  <v-autocomplete
    single-line
    :clearable="clearable"
    auto-select-first
    :items="availableItems"
    :search-input.sync="search"
    v-bind="$attrs"
    :hide-no-data="hideNoData"
    :value="value"
    :hint="hint"
    v-on="$listeners"
    :placeholder="placeholder"
    :multiple="multiselect"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-autocomplete>
</template>

<script>
import baseField from "@/utils/BaseField";

export default {
  name: "SelectWithAutocomplete",
  mixins: [baseField],
  props: {
    items: { type: Array, default: () => [] },
    value: { type: [String, Object, Array, Number] },
    multiselect: { type: Boolean, default: false },
    clearable: { type: Boolean, default: true },
  },
  data() {
    return {
      search: null,
    };
  },
  computed: {
    availableItems() {
      return !this.waitForInput || this.value || this.search ? this.items : [];
    },
    hideNoData() {
      return this.waitForInput && !this.search;
    },
    hint() {
      return this.waitForInput && !this.search
        ? this.$t("common.autoCompleteAsyncHint")
        : "";
    },
    waitForInput() {
      return (
        Object.prototype.hasOwnProperty.call(this.$attrs, "waitForInput") &&
        this.$attrs["waitForInput"] !== false
      );
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep.v-input__icon--clear > i {
  font-size: 16px !important;
}
::v-deep.v-input__icon--append > i {
  font-size: 16px !important;
}
</style>
