import axios from "@/plugins/http";
let urlApi = "api/login";
export default {
  GetUser(userId) {
    return axios.get(`${urlApi}/${userId}`);
  },
  UpdateUserModifiTime(userId) {
    return axios.put(`${urlApi}/${userId}`, {});
  },
};
