<template>
  <v-flex>
    <div v-show="!edit">
      <div v-if="label" class="label textColor--text">{{ label }}</div>
      <slot>{{ displayValue }}</slot>
    </div>
    <div v-show="edit">
      <v-select
        v-if="hasArrt(categoryTypes.SELECT)"
        :items="items"
        :value="newValue"
        :item-text="itemText"
        :label="label"
        v-bind="$attrs"
        v-on="listeners"
        @input="onChange"
      >
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </v-select>

      <v-text-field
        v-if="hasArrt(categoryTypes.TEXT)"
        :label="label"
        :value="newValue"
        v-bind="$attrs"
        v-on="listeners"
        @input="onChange"
      ></v-text-field>

      <v-textarea
        v-if="hasArrt(categoryTypes.TEXTAREA)"
        :label="label"
        :value="newValue"
        v-bind="$attrs"
        v-on="listeners"
        no-resize
        @input="onChange"
      ></v-textarea>

      <DatePicker
        v-if="hasArrt(categoryTypes.DATEPICKER)"
        :label="label"
        :value="newValue"
        v-bind="$attrs"
        v-on="listeners"
        @input="onChange"
      ></DatePicker>

      <TimePicker
        v-if="hasArrt(categoryTypes.TIMEPICKER)"
        :label="label"
        :value="newValue"
        v-bind="$attrs"
        v-on="listeners"
        @input="onChange"
      ></TimePicker>

      <div v-if="hasArrt(categoryTypes.BOOL)">
        <div v-if="label" class="label textColor--text">{{ label }}</div>
        <BoolField
          :paramValue="newValue"
          :labelTrue="boolLabelTrue"
          :labelFalse="boolLabelFalse"
          @onChange="(obj) => onChange(obj.value)"
          v-bind="$attrs"
          v-on="listeners"
        ></BoolField>
      </div>

      <div v-if="hasArrt(categoryTypes.SELECTTAGS)">
        <div v-if="label" class="label textColor--text">{{ label }}</div>
        <SelectTagsField2
          :selectedTags="newValue"
          :allTags="items"
          :label="label"
          v-bind="$attrs"
          v-on="listeners"
          @change="onChange"
        ></SelectTagsField2>
      </div>

      <SelectWithAutocomplete
        v-if="hasArrt(categoryTypes.AUTOCOMPLETE)"
        :name="label"
        :items="items"
        :item-text="itemText"
        darken
        :value="newValue"
        v-bind="$attrs"
        v-on="listeners"
        @input="onChange"
      >
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </SelectWithAutocomplete>

      <BaseAutocomplete
        v-if="hasArrt(categoryTypes.AUTOCOMPLETEASYNC)"
        :name="label"
        :items="items"
        darken
        :item-text="displayValueText"
        item-value="id"
        :value="newValue"
        :func="searchFunc"
        :hideEmptyData="hideEmptyData"
        :hint="hint"
        v-bind="$attrs"
        v-on="listeners"
        @input="onChange"
      >
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </BaseAutocomplete>
      <div v-if="hasArrt(categoryTypes.COMPLAINTASYNC)">
        <div v-if="label" class="label textColor--text">{{ label }}</div>
        <SelectComplaintsField
          :cplId="id"
          :label="label"
          :recalculate="edit"
          :value="newValue"
          :func="options"
          v-bind="$attrs"
          v-on="listeners"
          @input="onChange"
        ></SelectComplaintsField>
      </div>
    </div>
  </v-flex>
</template>

<script>
import DatePicker from "../Shared/DatePicker";
import TimePicker from "../Shared/TimePicker";
import BoolField from "../Fields/BooleanField";
import SelectTagsField2 from "../Fields/SelectTagsField2";
import CategoryFieldTypes from "@/models/categoryFieldTypes.js";
import SelectWithAutocomplete from "@/components/Basic/SelectWithAutocomplete";
import SelectComplaintsField from "../Fields/SelectComplaintsField";

export default {
  components: {
    DatePicker,
    TimePicker,
    BoolField,
    SelectTagsField2,
    SelectWithAutocomplete,
    SelectComplaintsField,
  },
  props: {
    label: String,
    boolLabelTrue: String,
    boolLabelFalse: String,
    edit: Boolean,
    options: [Array, Function],
    itemText: { type: [String, Array, Function], default: "text" },
    value: [String, Object, Array, Boolean, Number],
    id: String,
    displayValueText: { type: Function },
    hint: { type: String },
  },
  created() {
    if (this.edit) {
      this.loadOptions();
    }
  },
  data() {
    return {
      categoryTypes: CategoryFieldTypes,
      newValue: this._copyValue(this.value),
      items: [],
      hideEmptyData: false,
    };
  },
  computed: {
    displayValue() {
      if (typeof this.newValue === "object" && this.newValue !== null) {
        if (typeof this.itemText === "function") {
          return this.itemText(this.newValue);
        } else if (Array.isArray(this.newValue)) {
          return this.newValue.map((v) => v[this.itemText]).join(", ");
        } else if (this.$moment.isMoment(this.newValue)) {
          if (this.hasArrt(CategoryFieldTypes.DATEPICKER)) {
            return this.newValue.format("date");
          } else {
            return this.newValue.format("time");
          }
        } else {
          return this.newValue[this.itemText];
        }
      }
      return this.newValue;
    },
    listeners() {
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },
  },
  watch: {
    edit(val) {
      if (val) {
        this.loadOptions().then(() => {
          this.newValue = this._copyValue(this.value);
        });
      }
    },
    value(v) {
      if (v !== this.newValue) {
        this.newValue = v;
      }
      if (
        this.hasArrt(CategoryFieldTypes.AUTOCOMPLETEASYNC) &&
        v === undefined
      ) {
        this.items = [];
      }
    },
    options() {
      this.loadOptions();
    },
  },
  methods: {
    onChange(val) {
      this.$emit("input", val);
    },
    searchFunc(val) {
      return this.options(val).then((r) => (this.items = r));
    },
    hasArrt(name) {
      return (
        Object.prototype.hasOwnProperty.call(this.$attrs, name) &&
        this.$attrs[name] !== false
      );
    },
    loadOptions() {
      if (
        typeof this.options === "function" &&
        this.items.length === 0 &&
        !this.hasArrt(CategoryFieldTypes.AUTOCOMPLETEASYNC)
      ) {
        return this.options().then((res) => (this.items = res));
      } else if (Array.isArray(this.options)) {
        this.items = this.options;
      }
      return Promise.resolve();
    },
    _copyValue(value) {
      if (
        this.hasArrt(CategoryFieldTypes.DATEPICKER) ||
        this.hasArrt(CategoryFieldTypes.TIMEPICKER)
      ) {
        return value;
      }

      if (typeof value === "object") {
        if (Array.isArray(value)) {
          return [...value];
        } else if (
          value === null &&
          (this.hasArrt(CategoryFieldTypes.TEXT) ||
            this.hasArrt(CategoryFieldTypes.TEXTAREA))
        ) {
          return "";
        } else if (value !== null) {
          return this.items !== undefined && this.items.length > 0
            ? this.items.find((i) => i.id === this.value.id) || {}
            : { ...value };
        }
      } else {
        return value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.v-input__icon--clear > i {
  font-size: 16px !important;
}
::v-deep.v-input__icon--append > i {
  font-size: 16px !important;
}
div[textfield],
div[textareafield] {
  word-wrap: break-word;
}

.label {
  font-size: 13px;
}
</style>
