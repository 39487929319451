export const ADD_COLLECTION = "NOTIFICATIONS/ADD_COLLECTION";
export const DELETE_COLLECTION = "NOTIFICATIONS/DELETE_NOTIFICATIONS";
export const READ_COLLECTION = "NOTIFICATIONS/READ_NOTIFICATIONS";
export const MARK_AS_READ = "NOTIFICATIONS/MARK_AS_READ";
export const SET_PAGING_INFO = "NOTIFICATIONS/SET_PAGING_INFO";
export const DELETE = "NOTIFICATIONS/DELETE";
export const REFRESH_NOTIFICATIONS = "NOTIFICATIONS/REFRESH";
export const INCOMING_NEW_NOTIFICATION =
  "NOTIFICATIONS/INCOMING_NEW_NOTIFICATION";

export const NOTIFICATIONS = {
  ADD_COLLECTION,
  DELETE_COLLECTION,
  READ_COLLECTION,
  MARK_AS_READ,
  SET_PAGING_INFO,
  DELETE,
  INCOMING_NEW_NOTIFICATION,
  REFRESH_NOTIFICATIONS,
};
