export default {
  props: {
    value: { type: Boolean, required: true },
  },
  computed: {
    isShown: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) this.onClosed();

        this.$emit("input", value);
      },
    },
  },
};
