import { NOTIFICATIONS } from "./mutationConstants";
import BellNotificationApi from "../../services/BellNotificationApi";
import { EmitError } from "../../eventBus";

async function initiateNotifications(context, take) {
  try {
    const { notifications, totalCount, unreadCount, ...pagingInfo } =
      await BellNotificationApi.getNotificationsPaged(
        take,
        context.state.notifications.lastQueriedNotificationId
      );
    addNotifications(context, { notifications, totalCount, unreadCount });
    context.commit(NOTIFICATIONS.SET_PAGING_INFO, pagingInfo);
  } catch (e) {
    EmitError("Błąd po stronie serwera. Nie pobrano notyfikacji");
  }
}

async function deleteNotifications({ commit }) {
  try {
    await BellNotificationApi.deleteNotifications();
    commit(NOTIFICATIONS.DELETE_COLLECTION);
    return true;
  } catch (e) {
    EmitError("Błąd po stronie serwera. Nie udało sie usunąć notyfikacji");
    return false;
  }
}

async function deleteNotification({ commit }, idToRemove) {
  try {
    await BellNotificationApi.deleteNotification(idToRemove);
    commit(NOTIFICATIONS.DELETE, idToRemove);
  } catch (e) {
    EmitError("Błąd po stronie serwera. Nie udało sie usunąć notyfikacji");
  }
}

async function readNotifications({ commit }) {
  try {
    await BellNotificationApi.readNotifications();
    commit(NOTIFICATIONS.READ_COLLECTION);
    return true;
  } catch (e) {
    EmitError("Błąd po stronie serwera. Nie udało sie odznaczyć notyfikacji");
    return false;
  }
}

async function markNotificationAsRead({ commit }, notificationId) {
  const promise = BellNotificationApi.markNotificationAsRead(notificationId);
  return promise.then(() => commit(NOTIFICATIONS.MARK_AS_READ, notificationId));
}

function addNotifications(
  { commit },
  { notifications, totalCount, unreadCount }
) {
  commit(NOTIFICATIONS.ADD_COLLECTION, {
    items: notifications,
    totalCount,
    unreadCount,
  });
}

function refreshNotifications({ commit }, take) {
  BellNotificationApi.getNotificationsPaged(take).then((response) => {
    const {
      notifications,
      totalCount,
      unreadCount,
      isNextPage,
      lastNotificationId,
    } = response;

    commit(NOTIFICATIONS.REFRESH_NOTIFICATIONS, {
      items: notifications,
      totalCount,
      unreadCount,
      isNextPage,
      lastNotificationId,
    });
  });
}

function incomingNewNotification({ commit }, notifications) {
  commit(NOTIFICATIONS.INCOMING_NEW_NOTIFICATION, notifications[0]);
}

export const notificationActions = {
  initiateNotifications,
  deleteNotifications,
  deleteNotification,
  readNotifications,
  addNotifications,
  markNotificationAsRead,
  incomingNewNotification,
  refreshNotifications,
};
