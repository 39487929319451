import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import isEqual from "lodash/isEqual";
import moment from "@/plugins/moment";
import { vuexOidcCreateStoreModule } from "vuex-oidc";
import { oidcSettings } from "../configuration";
import settings from "./store/settings.module";
import DisplayListTypes from "@/models/displayListTypes.js";
import { notificationActions } from "./components/BellNotifications/actions";
import { notificationMutations } from "./components/BellNotifications/mutations";
import { notificationGetters } from "./components/BellNotifications/getters";
import UsersService from "./services/UsersService";
import ComplaintsService from "./services/ComplaintsService";
import * as CplStates from "./models/cplStates";

Vue.use(Vuex);

const defaultPagination = {
  page: 1,
  itemsPerPage: 12,
  totalItems: 0,
};

const initialState = {
  notifications: {
    data: [],
    lastQueriedNotificationId: null,
    isNextPage: false,
    totalCount: 0,
    unreadCount: 0,
    newNotificationId: null,
  },
  complaintOptions: {
    orderBy: {
      name: "REALISATIONDATE",
      desc: false,
    },
    insertDateFrom: null,
    insertDateTo: null,
    filters: {},
    selectedStates: [],
    display: DisplayListTypes.CARDS,
    pagination: defaultPagination,
  },
  customerComplaintOptions: {
    orderBy: {
      name: "REALISATIONDATE",
      desc: false,
    },
    display: DisplayListTypes.CARDS,
    selectedStates: [],
  },
  complaintDetails: {
    isCommentsOutdated: false,
  },
  departmentOptions: {
    pagination: defaultPagination,
  },
  contactOptions: {
    orderBy: {
      name: "SURNAME",
      desc: false,
    },
    insertDateFrom: moment().startOf("d").subtract(1, "M").format(),
    insertDateTo: moment().endOf("d").format(),
    filters: {},
    selectedTypes: [],
    display: DisplayListTypes.CARDS,
    pagination: defaultPagination,
    selectedContacts: [],
  },
  roleOptions: {
    orderBy: {
      name: "NAME",
      desc: false,
    },
    filters: {},
    display: DisplayListTypes.CARDS,
    pagination: defaultPagination,
  },
  userOptions: {
    orderBy: {
      name: "SURNAME",
      desc: false,
    },
    filters: {},
    display: DisplayListTypes.CARDS,
    pagination: defaultPagination,
  },
  dictParamOptions: {
    orderBy: {
      name: "NAME",
      desc: false,
    },
    search: "",
  },
  shared: {
    mobileSearchTextValue: null,
  },
};

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({ settings: state.settings }),
});

const store = new Vuex.Store({
  plugins: [vuexLocal.plugin],
  modules: {
    settings,
    oidcStore: vuexOidcCreateStoreModule(
      oidcSettings,
      // Optional OIDC store settings
      {
        namespaced: true,
        dispatchEventsOnWindow: true,
      },
      // Optional OIDC event listeners
      {
        userLoaded: (user) => {
          // prevent fetching restrictions when user is logging in (is not fully logged in yet)
          if (store.state.oidcStore.user) {
            store
              .dispatch("settings/fetchUserRestrictions", {
                userId: user.profile.userId,
              })
              .then((result) => {
                if (!result.isActive) {
                  store.dispatch("oidcStore/signOutOidc");
                }
              });
            store.dispatch("loadComplaintStates");
          }
        },
        // userUnloaded: () => console.log("OIDC user is unloaded"),
        // accessTokenExpiring: () => {console.log("Access token will expire")},
        // accessTokenExpired: () => console.log("Access token did expire"),
        // silentRenewError: () => console.log("OIDC user is unloaded"),
        userSignedOut: () => {
          window.localStorage.removeItem("localId");
        },
      }
    ),
  },
  state: { ...initialState },
  getters: {
    ...notificationGetters,
  },
  mutations: {
    ...notificationMutations,
    saveComplaintOption(state, option) {
      state.complaintOptions = { ...state.complaintOptions, ...option };
    },
    saveCustomerComplaintOption(state, option) {
      state.customerComplaintOptions = {
        ...state.customerComplaintOptions,
        ...option,
      };
    },
    saveDepartmentOption(state, option) {
      state.departmentOptions = { ...state.departmentOptions, ...option };
    },
    saveContactOption(state, option) {
      state.contactOptions = { ...state.contactOptions, ...option };
    },
    saveRoleOption(state, option) {
      state.roleOptions = { ...state.roleOptions, ...option };
    },
    saveUserOption(state, option) {
      state.userOptions = { ...state.userOptions, ...option };
    },
    saveDictParamOptions(state, option) {
      state.dictParamOptions = { ...state.dictParamOptions, ...option };
    },
    setDefaultState(state) {
      const s = { ...initialState };
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    setComplaintStates(state, complaintStates) {
      state.complaintOptions.selectedStates.forEach((complaintState) => {
        const cpl = complaintStates.find((x) => x.id === complaintState.id);
        if (cpl && cpl.count) {
          complaintState.count = cpl.count;
        }
      });
      initialState.complaintOptions.selectedStates = complaintStates;
    },
    setMobileSearchTextValue(state, searchValue) {
      state.shared.mobileSearchTextValue = searchValue;
    },
    setIsComplaintCommentsOutdated(state, value) {
      state.complaintDetails.isCommentsOutdated = value;
    },
  },
  actions: {
    ...notificationActions,
    saveComplaintOption(context, option) {
      const counter = localStorage.getItem("saveComplaintOptionCounter") || 0;
      localStorage.setItem(
        "saveComplaintOptionCounter",
        Number.parseInt(counter) + 1
      );

      if (checkIfDiffrentValues(context.state.complaintOptions, option)) {
        context.commit("saveComplaintOption", option);
        const { ...options } = context.state.complaintOptions;
        return UsersService.saveSettings(options);
      }
    },
    saveCustomerComplaintOption(context, option) {
      context.commit("saveCustomerComplaintOption", option);
    },
    saveDepartmentOption(context, option) {
      context.commit("saveDepartmentOption", option);
    },
    saveContactOption(context, option) {
      context.commit("saveContactOption", option);
    },
    saveRoleOption(context, option) {
      context.commit("saveRoleOption", option);
    },
    saveUserOption(context, option) {
      context.commit("saveUserOption", option);
    },
    saveDictParamOptions(context, option) {
      context.commit("saveDictParamOptions", option);
    },
    setDefaultState(context) {
      context.commit("setDefaultState");
    },
    fetchUserComplaintSettings({ commit }, userId) {
      return UsersService.getSettings(userId).then((res) => {
        if (res) {
          const responseObject = JSON.parse(res);
          commit("saveComplaintOption", responseObject);
        }
      });
    },
    loadComplaintStates({ commit }) {
      return ComplaintsService.GetComplaintsStates().then((res) =>
        commit(
          "setComplaintStates",
          res.filter(
            (x) => x.id !== CplStates.CLOSED && x.id !== CplStates.CANCELED
          )
        )
      );
    },
    setMobileSearchTextValue(context, searchValue) {
      context.commit("setMobileSearchTextValue", searchValue);
    },
    setIsComplaintCommentsOutdated(context, value) {
      context.commit("setIsComplaintCommentsOutdated", value);
    },
  },
});

function checkIfDiffrentValues(storeValue, newValues) {
  if (typeof newValues === "object" && newValues !== null) {
    const keys = Object.keys(newValues);

    return keys.some((key) => !isEqual(storeValue[key], newValues[key]));
  }
}

export const mapProps = function (
  props = new Array(""),
  { options = "", action = "" } = {}
) {
  return props.reduce((obj, prop) => {
    const computedProp = {
      get() {
        return this.$store.state[options][prop];
      },
      set(newValue) {
        this.$store.dispatch(action, { [prop]: newValue });
      },
    };
    obj[prop] = computedProp;
    return obj;
  }, {});
};

export default store;
