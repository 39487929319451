<template>
  <v-menu
    v-model="openMenu"
    :close-on-content-click="false"
    max-width
    transition="scale-transition"
    offset-y
    min-width="290px"
    :disabled="!canChange"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        slot="activator"
        v-model="dateDisplay"
        :label="label"
        readonly
        prepend-icon="stem-calendar"
        color="fields"
        :error-messages="errors.date"
        @input="$v.$touch()"
        @blur="$v.$touch()"
        :disabled="!canChange"
        :style="canChange ? 'cursor: pointer' : 'cursor : default'"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      no-title
      :value="date"
      @input="onDateChange"
      locale="pl"
      color="fields"
      v-bind="attrs"
      :min="minAllowedDate"
      :max="maxAllowedDate"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import validationThings from "@/mixins/validation-things";
import MenuMixin from "@/mixins/menuMixin.js";

export default {
  name: "DatePicker",
  mixins: [validationThings, MenuMixin],
  props: {
    label: String,
    required: Boolean,
    value: {
      required: true,
    },
    canChange: {
      type: Boolean,
      default: true,
    },
    maxDate: {
      type: Object,
    },
    minDate: {
      type: Object,
    },
  },
  computed: {
    attrs() {
      return { ...this.$attrs };
    },
    date() {
      if (this.value == null) {
        return undefined;
      } else if (this.$moment.isMoment(this.value)) {
        return this.$moment(this.value).format("date");
      } else if (typeof this.value === "string") {
        return this.$moment(this.value).format("date");
      }
      return undefined;
    },
    dateDisplay() {
      return this.date
        ? this.$moment(this.date, "YYYY-MM-DD").format("displayDate")
        : "";
    },
    maxAllowedDate() {
      return this.maxDate
        ? this.$moment(this.maxDate).format("date")
        : undefined;
    },
    minAllowedDate() {
      return this.minDate
        ? this.$moment(this.minDate).format("date")
        : undefined;
    },
  },
  methods: {
    onDateChange(newDate) {
      const date = newDate
        ? this.$moment(newDate, "YYYY-MM-DD").format()
        : null;

      this.$emit("input", date);
    },
  },
  watch: {
    maxDate(value) {
      if (!value) return;

      const maxDate = this.$moment(value);
      const selectedDate = this.value ? this.$moment(this.value) : null;

      if (this.$moment(selectedDate).isAfter(maxDate)) {
        const date = this.$moment(value).format();
        this.$emit("input", date);
      }
    },
    minDate(value) {
      if (!value) return;

      const minDate = this.$moment(value);
      const selectedDate = this.value ? this.$moment(this.value) : null;

      if (this.$moment(selectedDate).isBefore(minDate)) {
        const date = this.$moment(value).format();
        this.$emit("input", date);
      }
    },
  },
  validations: {
    date: {
      required: (value, vm) => {
        return vm.required ? !!value : true;
      },
    },
  },
};
</script>
