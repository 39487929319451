<template>
  <v-tooltip bottom :disabled="title == undefined">
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <slot></slot>
      </span>
    </template>
    <span>{{ title }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    title: String,
  },
};
</script>
