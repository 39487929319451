export const oidcSettings = {
  authority: "https://auth.pirios.com",
  client_id: window.location.origin
    .replace("http://", "")
    .replace("https://", ""),
  redirect_uri: window.location.origin + "/oidc-callback",
  response_type: "id_token token",
  scope: "openid profile offline_access api",
  automaticSilentRenew: true,
  silent_redirect_uri: window.location.origin + "/silent-refresh-oidc",
};
