import Vue from "vue";
import vuetify from "./plugins/vuetify";
import "./utils/Filters";
import "./utils/BaseComponentsRegister";
import App from "./IndexApp.vue";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";
import Vuelidate from "vuelidate";
import IconBox from "./components/Shared/IconBox";
import TooltipComponent from "./components/Shared/TooltipComponent";
import TooltipTruncate from "./components/Shared/TooltipTruncate";
import moment from "./plugins/moment";
import GenerateRules from "./plugins/GenerateRules";
import VueAcl from "vue-browser-acl";
import VueFriendlyIframe from "vue-friendly-iframe";

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(
  VueAcl,
  () => store.getters["settings/getUserRestrictions"],
  GenerateRules()
);

Vue.component("IconBox", IconBox);
Vue.component("TooltipComponent", TooltipComponent);
Vue.component("TooltipTruncate", TooltipTruncate);
Vue.use(VueFriendlyIframe);

new Vue({
  router,
  store,
  i18n,
  moment,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

const _isAuthenticatedWatch = store.watch(
  () => store.getters["oidcStore/oidcIsAuthenticated"],
  async (isAuthenticated) => {
    if (!isAuthenticated) return;
    onUserIsAuthenticated();
    _isAuthenticatedWatch();
  }
);

const onUserIsAuthenticated = () => {
  store.dispatch("fetchUserComplaintSettings");
};
