<template>
  <signal-r-provider @displayLogoutPopup="onDisplayLogoutPopup">
    <div v-resize="onResize" ref="app-container">
      <RequireLogoutPopup :showModal="displayLogoutPopup" />
      <MainMenu
        v-bind:drawerVisible="this.drawer"
        v-bind:toggleDrawer="this.toggleDrawer"
      ></MainMenu>
      <MainToolbar v-bind:toggleDrawer="this.toggleDrawer" />
      <v-main :class="`main-content${isMobile ? ' mobile' : ''}`">
        <SnackbarNotification />
        <v-container xs fluid>
          <v-layout wrap>
            <v-flex>
              <router-view></router-view>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </div>
  </signal-r-provider>
</template>
<style lang="scss">
@import "assets/stemIcons.css";
</style>

<script>
import { mapActions, mapGetters } from "vuex";

import MainToolbar from "./components/MainToolbar";
import SnackbarNotification from "./components/SnackbarNotification";
import MainMenu from "./components/MainMenu";
import SignalRProvider from "./components/SignalRProvider";
import RequireLogoutPopup from "./components/RequireLogoutPopup.vue";

export default {
  name: "App",
  components: {
    SignalRProvider,
    MainToolbar,
    MainMenu,
    SnackbarNotification,
    RequireLogoutPopup,
  },
  data: () => ({
    drawer: false,
    displayLogoutPopup: false,
  }),
  computed: {
    ...mapGetters("settings", ["isMobile"]),
    screenSize() {
      return screen.width;
    },
    renderDrawer() {
      return this.isMobile ? this.drawer : true;
    },
  },
  methods: {
    ...mapActions("settings", [
      "setUiMode",
      "setMobileViewMode",
      "getAttachmentsRestriction",
    ]),
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    onResize() {
      const div = this.$refs["app-container"];
      const width = div.offsetWidth;
      this.setMobileViewMode(width);
    },
    onDisplayLogoutPopup() {
      this.displayLogoutPopup = true;
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  mounted() {
    this.setUiMode();
    this.getAttachmentsRestriction();
  },
};
</script>

<style scoped lang="scss">
.main-content {
  margin: 64px 0 0 60px;

  &.mobile {
    margin-left: 0;
  }
}
</style>
