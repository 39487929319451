import axios from "axios";
import { authToken } from "./auth-header";
import { EmitServerError } from "@/utils/ServerErrorHandler.js";

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    config.headers.common["Authorization"] = authToken();
    config.headers.common["Cache-Control"] = "no-cache";
    config.headers.get["If-Modified-Since"] = "0";
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.headers.pagination !== undefined) {
        return {
          data: response.data,
          pagination: JSON.parse(response.headers.pagination),
        };
      }
      return response.data;
    }
    if (response.status === 204) return undefined;
    return response;
  },
  (error) => {
    EmitServerError(error);
    return Promise.reject(error);
  }
);
axios.defaults.timeout = 60000;

export default axios;

export const paramsSerializer = (params) => {
  const keys = Object.keys(params);
  let options = "";

  keys.forEach((key) => {
    const isParamTypeObject = typeof params[key] === "object";
    const isParamTypeArray = isParamTypeObject && Array.isArray(params[key]);

    if (!isParamTypeObject) {
      options += `${key}=${params[key]}&`;
    }

    if (isParamTypeObject && isParamTypeArray) {
      params[key].forEach((element) => {
        if (typeof element === "object") {
          options += `${key}=${JSON.stringify(element)}&`;
        } else {
          options += `${key}=${element}&`;
        }
      });
    }
  });

  return options ? options.slice(0, -1) : options;
};
