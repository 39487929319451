<template>
  <v-navigation-drawer v-if="!isMobile" expand-on-hover permanent>
    <v-list nav>
      <v-list-item
        v-for="(item, index) in menu"
        :key="index"
        :to="{ name: item.name }"
        :exact="item.exact"
      >
        <v-list-item-icon>
          <IconBox :styles="{ 'font-size': '28px' }">
            {{ `stem-${item.menuIcon}` }}
          </IconBox>
        </v-list-item-icon>
        <v-list-item-content class="item-name">
          {{ $t(`menu.${item.name}`).toUpperCase() }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "MainMenu",
  data() {
    return {
      drawer: true,
      items: [],
      mini: true,
      right: null,
    };
  },
  props: {
    drawerVisible: Boolean,
    toggleDrawer: Function,
  },
  computed: {
    ...mapGetters("settings", ["hasRestriction", "isMobile"]),
    menu() {
      return this.routes.filter((it) =>
        this.hasRestriction(it.restrictionCode)
      );
    },
    routes() {
      var mainRoute = this.$router.options.routes.find(
        (item) => (item.meta || {}).baseRoute == true
      );
      return mainRoute.children
        .filter((item) => (item.meta || {}).menuIcon)
        .map((i) => {
          return { ...i, ...i.meta };
        });
    },
    visible: {
      get() {
        return this.isMobile ? this.drawerVisible : true;
      },
      set(visible) {
        visible !== this.drawerVisible && this.toggleDrawer(visible);
        return visible;
      },
    },
  },
  methods: {
    ...mapActions({
      setDefaultState: "setDefaultState",
    }),
    setDefault() {
      this.setDefaultState();
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style scoped lang="scss">
.v-navigation-drawer {
  position: fixed;
  min-width: 60px;
  height: 100%;
  margin-top: 64px;
  background-color: $main-main;
  z-index: 3;

  .v-list-item {
    .v-list-item__icon {
      width: 30px;
      height: 30px;
      padding-left: 5px;
      color: $main-menu-item-text;
    }

    .item-name {
      color: $main-menu-item-text;
      white-space: nowrap;
    }
  }

  .v-list-item--active {
    border-left: 3px solid $main-menu-selected-item-text;
    border-radius: 0;
    background-color: $main-menu-selected-item-background;

    .v-list-item__icon {
      margin-left: -3px;
      color: $main-menu-selected-item-text;
    }

    .item-name {
      white-space: nowrap;
      color: $main-menu-selected-item-text;
    }
  }

  .v-list-item--active:before {
    opacity: 0;
  }

  .v-list-item:hover::before {
    opacity: 0.1;
  }
}

.v-navigation-drawer:hover {
  border-radius: 0 15px 0 0;
}

.v-list {
  padding: 0;
}
</style>
