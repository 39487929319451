import store from "../store";
import { isMoment } from "moment";

export default function WithUser(data) {
  const res = { ...data };

  let user = store.state.oidcStore.user;
  res.userFullName = `${user.name}`;
  res.userId = user.userId;
  res.userBrowserInfo = navigator.userAgent;

  Object.keys(res).forEach((el) => {
    if (isMoment(res[el])) {
      res[el] = res[el].format("YYYY-MM-DDTHH:mm:ss");
    }
  });

  return res;
}
