<template>
  <v-menu
    v-model="showWindow"
    :close-on-content-click="false"
    max-width
    transition="scale-transition"
    offset-y
    min-width="290px"
    :disabled="!canChange"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        slot="activator"
        :label="label"
        v-model="time"
        readonly
        prepend-icon="stem-hour"
        color="fields"
        :error-messages="errors.time"
        @input="$v.$touch()"
        @blur="$v.$touch()"
        :disabled="!canChange"
        :style="canChange ? 'cursor: pointer' : 'cursor : default'"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="showWindow"
      no-title
      scrollable
      format="24hr"
      color="fields"
      :value="time"
      :allowedMinutes="allowedMinutes"
      :max="maxAllowedTime"
      :min="minAllowedTime"
      @input="onTimeChange"
      @change="showWindow = false"
    >
    </v-time-picker>
  </v-menu>
</template>

<script>
import validationThings from "@/mixins/validation-things";

export default {
  name: "TimePicker",
  mixins: [validationThings],
  props: {
    label: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    value: {
      value: String,
    },
    canChange: {
      type: Boolean,
      default: true,
    },
    allowedMinutes: {
      type: Array || undefined,
    },
    maxTime: {
      type: Object,
    },
    minTime: {
      type: Object,
    },
  },
  data() {
    return {
      showWindow: false,
      timeFormat: "HH:mm",
    };
  },
  computed: {
    time: {
      get: function () {
        if (this.value == null) {
          return undefined;
        } else if (this.$moment.isMoment(this.value)) {
          return this.$moment(this.value).format("HH:mm");
        } else if (typeof this.value === "string") {
          return this.$moment(this.value).format("HH:mm");
        }
        return undefined;
      },
      set: function () {},
    },
    maxAllowedTime() {
      return this.maxTime
        ? this.$moment(this.maxTime).format(this.timeFormat)
        : undefined;
    },
    minAllowedTime() {
      return this.minTime
        ? this.$moment(this.minTime).format(this.timeFormat)
        : undefined;
    },
  },
  methods: {
    onTimeChange(newTime) {
      const time = newTime ? this.$moment(newTime, "HH:mm").format() : null;
      this.$emit("input", time);
    },
  },
  watch: {
    maxTime(value) {
      if (!value) return;

      const maxDateTime = this.$moment(value);
      const selectedTime = this.value ? this.$moment(this.value) : null;

      if (this.$moment(selectedTime).isAfter(maxDateTime)) {
        let time;

        if (this.allowedMinutes) {
          this.allowedMinutes.forEach((minutes) => {
            const maxTimeWithMinutes = this.$moment(value).minutes(minutes);
            if (this.$moment(maxTimeWithMinutes).isBefore(maxDateTime)) {
              time = this.$moment(maxTimeWithMinutes).format();
            }
          });
          if (!time) {
            time = this.$moment(value)
              .subtract(1, "hour")
              .minutes(this.allowedMinutes[this.allowedMinutes.length - 1]);
          }
        } else {
          time = this.$moment(maxDateTime).format();
        }

        this.$emit("input", time);
      }
    },
    minTime(value) {
      if (!value) return;

      const minDateTime = this.$moment(value);
      const selectedTime = this.value ? this.$moment(this.value) : null;

      if (this.$moment(selectedTime).isBefore(minDateTime)) {
        let time;

        if (this.allowedMinutes) {
          this.allowedMinutes.forEach((minutes) => {
            const minTimeWithMinutes = this.$moment(value).minutes(minutes);
            if (this.$moment(minTimeWithMinutes).isAfter(minDateTime)) {
              time = this.$moment(minTimeWithMinutes).format();
            }
          });

          if (!time) {
            time = this.$moment(value)
              .add(1, "hour")
              .minutes(this.allowedMinutes[0]);
          }
        } else {
          time = this.$moment(minDateTime).format();
        }

        this.$emit("input", time);
      }
    },
  },
  validations: {
    time: {
      required: (value, vm) => {
        return vm.required ? !!value : true;
      },
    },
  },
};
</script>
