<template>
  <v-layout id="tags-container" v-bind:class="isMobile && 'mobile'">
    <v-flex xs6 pr-3 v-bind:class="isMobile && 'stretched-input'">
      <v-combobox
        :value="isFocus ? selectedTags : []"
        :items="modelAllTags"
        :search-input.sync="searchedTag"
        :hide-no-data="searchedTagShow"
        :label="$t('complaints.tags')"
        @blur="blurHandler"
        @change="onChangeHandler"
        @focus="onFocusHandler"
        :maxlength="maxTagLength"
        multiple
        hide-selected
        solo
      >
        <template #selection></template>
        <template #no-data>
          <v-list-item>
            <span class="subheading">{{ $t("complaints.enterToAddTag") }}</span>
            <v-chip color="newChipColor" dark small class="new-chip">
              {{ searchedTag }}
            </v-chip>
          </v-list-item>
        </template>
      </v-combobox>
    </v-flex>
    <v-flex
      xs6
      pl-3
      row
      fill-height
      v-bind:class="isMobile && 'stretched-input'"
      class="chip-container"
    >
      <v-chip
        color="chipColor"
        text-color="textColor"
        class="elevation-2"
        v-for="(tag, index) in selectedTags"
        :key="index"
      >
        <TooltipTruncate :title="tag.text" class="pr-2">
          {{ tag.text }}
        </TooltipTruncate>
        <v-icon small color="dark" @click="removeTag(index)">fa-times</v-icon>
      </v-chip>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";

const isCandidateForNewTag = (item) => typeof item === "string";
const isNewlyAddedTag = (item) => item.id === 0;

export default {
  model: {
    prop: "selectedTags",
    event: "change",
  },
  props: {
    selectedTags: { type: Array, required: true },
    allTags: { type: Array },
    label: String,
    maxTagLength: { type: Number, default: 50 },
  },
  data() {
    return {
      searchedTag: null,
      isFocus: false,
      modelAllTags: [...this.allTags],
    };
  },
  computed: {
    ...mapGetters("settings", ["isMobile"]),
    searchedTagShow() {
      return !(this.searchedTag || "").trim();
    },
  },
  methods: {
    removeTag(index) {
      const tag = this.selectedTags[index];
      const afterRemovingTag = this.selectedTags.filter(
        (st) => st.text !== tag.text
      );
      this.$emit("change", afterRemovingTag);

      if (!isNewlyAddedTag(tag)) {
        this.modelAllTags.push(tag);
      }
    },
    _duplicatesExist(newTag) {
      const compareByText = (t1) => (t2) => t1.text.trim() === t2.text.trim();
      const compareWithNewTag = compareByText(newTag);

      return (
        this.selectedTags.some(compareWithNewTag) ||
        this.modelAllTags.some(compareWithNewTag)
      );
    },
    _isTagTextEmpty(newTag) {
      return newTag.text.trim() === "";
    },
    _isNewTagInValid(newTag) {
      return this._isTagTextEmpty(newTag) || this._duplicatesExist(newTag);
    },

    onFocusHandler() {
      this.isFocus = true;
    },
    blurHandler() {
      this.isFocus = false;
    },
    onChangeHandler(newItems) {
      const newItem = newItems.pop();
      const selectedTags = [...this.selectedTags];

      if (isCandidateForNewTag(newItem)) {
        const newTag = { id: 0, text: newItem };
        if (this._isNewTagInValid(newTag)) return;

        selectedTags.push(newTag);
      } else {
        if (!selectedTags.some((x) => x.text === newItem.text)) {
          selectedTags.push(newItem);
        }
        this.modelAllTags = this.modelAllTags.filter(
          (t) => t.text !== newItem.text
        );
        this.searchedTag = null;
      }

      this.$emit("change", selectedTags);
    },
  },
  watch: {
    allTags() {
      this.modelAllTags = [...this.allTags];
    },
  },
};
</script>

<style lang="scss" scoped>
.chip-container {
  margin: 0;
}
::v-deep .v-chip {
  max-width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
}
::v-deep .v-chip__content {
  max-width: 100%;

  .text-truncate {
    margin-right: 5px;
  }
}
::v-deep .v-chip__content > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
::v-deep .v-chip .vi-icon {
  cursor: pointer !important;
}
#tags-container.mobile {
  display: flex;
  flex-direction: column;
}

.new-chip {
  margin: 0 0 0 10px;
}
</style>
