import Acl from "browser-acl";
import * as GlobalRestrictions from "@/utils/GlobalRestrictionCodes.js";
import * as CplCategoryRestrictions from "@/utils/CplCategoryRestrictionCodes.js";

const GenerateRules = () => {
  const acl = new Acl();

  acl.subjectMapper = (s) => (!isNaN(s) ? "number" : s);

  for (const restriction in GlobalRestrictions) {
    acl.rule(restriction, (userRestrictions) =>
      userRestrictions.global.some((r) => r.code === restriction)
    );
  }

  for (const restriction in CplCategoryRestrictions) {
    acl.rule(
      restriction,
      "number",
      (userRestrictions, categoryId) =>
        userRestrictions.category[+categoryId] !== undefined &&
        userRestrictions.category[+categoryId].some(
          (r) => r.code === restriction
        )
    );
  }
  return acl;
};

export default GenerateRules;
