<template>
  <v-select
    :placeholder="placeholder"
    v-bind="attrs"
    :value="value"
    @input="updateValue($event)"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-select>
</template>

<script>
import baseField from "@/utils/BaseField";

export default {
  name: "BaseSelect",
  mixins: [baseField],
  props: {
    required: { type: Boolean, default: false },
    name: String,
    darken: { type: Boolean, default: false },
    value: { type: [Array, Number, String, Object] },
  },
  computed: {
    placeholder() {
      return this.required ? `*   ${this.name}` : this.name;
    },
    attrs() {
      let inputStyle = this.darken
        ? { filled: true, singleLine: true }
        : { solo: true };
      return { ...inputStyle, ...this.$attrs };
    },
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.v-input__slot:before {
  border-color: #fbfbfb !important;
}
::v-deep.v-select__selection--disabled {
  color: rgba(0, 0, 0, 0.87) !important;
}
::v-deep.v-input__icon--clear > i {
  font-size: 16px !important;
}
::v-deep.v-input__icon--append > i {
  font-size: 16px !important;
}
</style>
