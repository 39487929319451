import "@fortawesome/fontawesome-free/css/all.css";
import Vue from "vue";
import Vuetify, { VLayout } from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";
import i18n from "./i18n";

const ICONS = {
  "stem-calendar": "stem-calendar",
  "stem-search": "stem-search",
  "stem-hour": "stem-hour",
  "stem-personList": "stem-personList",
  "stem-complaint": "stem-complaint",
  "stem-megaphone": "stem-megaphone",
  "stem-person": "stem-person",
  "stem-avatar": "stem-avatar",
  clear: "stem-times",
};

Vue.use(Vuetify);

export default new Vuetify({
  components: { VLayout },
  theme: {
    themes: {
      light: {
        loaderColor: "#9364AA",
        toolbarIcons: "#000000",
        badgeText: "#FFFFFF",
        badgeBackground: "#7BC57B",
        roundButton: "#7BC57B",
        roundButtonText: "#FFFFFF",
        iconButton: "#000000",
        textColor: "#000000",
        fields: "#67B1E2",
        chipColor: "#ffffff",
        newChipColor: "#67B1E2",
        loadingPanelColor: "#FFFFFF",
        loadingPanelBackground: "#67B1E2",
        navigationPageButtons: "#67B1E2",
        selectionPickerSelectionItem: "#ffffff",
        error: "#F15C56",
        info: "#67B1E2",
        success: "#7BC57B",
        warning: "#FFC731",
        disabled: "#E0E0E0",
        priority_critical: "#E0413D",
        priority_serious: "#E05D3D",
        priority_caution: "#E05D3D",
        priority_normal: "#473DE0",
        priority_standby: "#473DE0",
        priority_off: "#B9B7A7",
      },
      dark: {
        loaderColor: "#9364AA",
        toolbarIcons: "#000000",
        badgeText: "#FFFFFF",
        badgeBackground: "#7BC57B",
        roundButton: "#7BC57B",
        roundButtonText: "#FFFFFF",
        iconButton: "#000000",
        textColor: "#000000",
        fields: "#7BC57B",
        chipColor: "#ffffff",
        newChipColor: "#67B1E2",
        loadingPanelColor: "#FFFFFF",
        loadingPanelBackground: "#67B1E2",
        navigationPageButtons: "#67B1E2",
        selectionPickerSelectionItem: "#ffffff",
        error: "#F15C56",
        info: "#67B1E2",
        success: "#7BC57B",
        warning: "#FFC731",
        disabled: "#E0E0E0",
        priority_critical: "#FF3838",
        priority_serious: "#FFB302",
        priority_caution: "#FCE83A",
        priority_normal: "#56F000",
        priority_standby: "#2DCCFF",
        priority_off: "#A4ABB6",
      },
    },
  },
  customProperties: true,
  icons: {
    iconfont: "fa",
    values: ICONS,
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
});
