<template>
  <v-dialog :value="showModal" persistent>
    <div class="logout-popup-content">
      <div class="header">
        {{ `${$t("common.warning")}!` }}
      </div>
      <div class="text">
        {{ $t("restrictions.administratorChangedRestrictions") }}
      </div>
      <div class="footer">
        <v-btn
          rounded
          color="roundButton roundButtonText--text"
          @click="onLogoutButtonClick"
        >
          {{ $t("restrictions.refreshPage") }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "RequireLogoutPopup",
  props: {
    showModal: { type: Boolean },
  },
  methods: {
    onLogoutButtonClick() {
      this.$router.go();
    },
  },
};
</script>

<style scoped lang="scss">
.logout-popup-content {
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;

  .header {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-left: 50px;
    color: $text-title;
    font-size: 20px;
    font-weight: bold;
  }

  .text {
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    padding: 50px;
  }

  .footer {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }
}
</style>
