import { NOTIFICATIONS } from "./mutationConstants";

export const notificationMutations = {
  [NOTIFICATIONS.ADD_COLLECTION](state, payload) {
    for (const notification of payload.items) {
      state.notifications.data.push(notification);
    }
    state.notifications.totalCount = payload.totalCount;
    state.notifications.unreadCount = payload.unreadCount;
  },
  [NOTIFICATIONS.DELETE_COLLECTION](state) {
    state.notifications.data = [];
    state.notifications.isNextPage = false;
  },
  [NOTIFICATIONS.DELETE](state, idToRemove) {
    const deletedNotification = state.notifications.data.find(
      (item) => item.id === idToRemove
    );

    const indexToRemove = state.notifications.data
      .map((n) => n.id)
      .indexOf(idToRemove);
    state.notifications.data.splice(indexToRemove, 1);

    if (deletedNotification.status === BellNotificationStatus.UNREAD) {
      state.notifications.unreadCount = state.notifications.unreadCount - 1;
    }
    state.notifications.totalCount = state.notifications.totalCount - 1;
  },
  [NOTIFICATIONS.READ_COLLECTION](state) {
    state.notifications.data.forEach(
      (n) => (n.status = BellNotificationStatus.READ)
    );
  },
  [NOTIFICATIONS.MARK_AS_READ](state, notificationId) {
    state.notifications.data
      .filter((n) => n.id === notificationId)
      .forEach((n) => {
        n.status = BellNotificationStatus.READ;
      });

    state.notifications.unreadCount = state.notifications.unreadCount - 1;
  },
  [NOTIFICATIONS.SET_PAGING_INFO](state, { lastNotificationId, isNextPage }) {
    state.notifications.lastQueriedNotificationId = lastNotificationId;
    state.notifications.isNextPage = isNextPage;
  },
  [NOTIFICATIONS.INCOMING_NEW_NOTIFICATION](state, payload) {
    state.notifications.newNotificationId = payload.id;
  },
  [NOTIFICATIONS.REFRESH_NOTIFICATIONS](state, payload) {
    state.notifications.data = payload.items;
    state.notifications.totalCount = payload.totalCount;
    state.notifications.unreadCount = payload.unreadCount;
    state.notifications.isNextPage = payload.isNextPage;
    state.notifications.lastQueriedNotificationId = payload.lastNotificationId;
  },
};

export const BellNotificationStatus = {
  UNREAD: 1,
  READ: 2,
  DELETED: 3,
};
