import LoginService from "@/services/LoginService";
import ComplaintService from "@/services/ComplaintsService";

const MOBILE_WIDTH_BREAKPOINT = 720;

const initialState = () => ({
  userRestrictions: {
    isAdminRole: false,
    globalRestrictions: [],
    cplCategoryRestrictions: {},
  },
  mobileUi: false,
});

export default {
  namespaced: true,
  state: initialState,
  getters: {
    hasRestriction: (state) => (code) => {
      return !!state.userRestrictions.globalRestrictions.find(
        (item) => item.code == code
      );
    },
    hasAnyRestriction: (state) => {
      return !!state.userRestrictions.globalRestrictions.length;
    },
    getUserRestrictions: (state) => {
      return {
        isAdminRole: state.isAdminRole,
        global: state.userRestrictions.globalRestrictions,
        category: state.userRestrictions.cplCategoryRestrictions,
        subCategory: state.userRestrictions.cplSubcategoryRestrictions,
      };
    },
    isMobile: (state) => {
      return state.mobileUi;
    },
    getAttachmentsRestriction: (state) => {
      return state.attachmentsRestriction;
    },
  },
  mutations: {
    SET_USER_RESTRICTIONS(state, restrictions) {
      state.userRestrictions = restrictions;
    },
    SET_USER_IS_ADMIN(state, isAdmin) {
      state.isAdminRole = isAdmin;
    },
    SET_MOBILE_UI(state, isMobile) {
      state.mobileUi = isMobile;
    },
    SET_ATTACHMENTS_RESTRICTION(state, restrictions) {
      state.attachmentsRestriction = restrictions;
    },
  },
  actions: {
    fetchUserRestrictions({ commit }, { userId }) {
      return LoginService.GetUser(userId).then((userInfo) => {
        commit(
          "SET_USER_RESTRICTIONS",
          !userInfo.isError ? userInfo.restrictions : initialState()
        );
        commit("SET_USER_IS_ADMIN", userInfo.isAdmin);
        return userInfo;
      });
    },
    setUiMode({ commit }) {
      commit("SET_MOBILE_UI", screen.width < MOBILE_WIDTH_BREAKPOINT);
    },
    setMobileViewMode({ commit }, value) {
      commit("SET_MOBILE_UI", value < MOBILE_WIDTH_BREAKPOINT);
    },
    getAttachmentsRestriction({ commit }) {
      return ComplaintService.GetAttachmentRestrictions().then((response) => {
        commit("SET_ATTACHMENTS_RESTRICTION", response);
      });
    },
  },
};
