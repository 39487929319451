<template>
  <v-autocomplete
    :placeholder="placeholder"
    auto-select-first
    clearable
    v-bind="attrs"
    :value="value"
    :items="options"
    :hide-no-data="!search || isLoading || hideEmptyData"
    :search-input.sync="search"
    :hint="search || attrs.readonly ? '' : hintValue"
    :loading="isLoading"
    @input="updateValue($event)"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
      ><slot :name="slot" v-bind="scope"
    /></template>
  </v-autocomplete>
</template>

<script>
import baseField from "@/utils/BaseField";
import axios from "@/plugins/http";

export default {
  name: "BaseAutocomplete",
  mixins: [baseField],
  props: {
    value: { type: [Array, Number, String, Object] },
    func: { type: Function },
    items: { type: Array, default: () => [] },
    hideEmptyData: { type: Boolean, default: false },
    hint: { type: String },
  },
  data() {
    return {
      search: null,
      isLoading: false,
      request: null,
    };
  },
  created() {
    if (typeof this.value === "object" && this.value !== null) {
      this.search = this.value.text;
    }
  },
  computed: {
    options() {
      if (this.isValueClear && (!this.search || !this.search.trim())) return [];

      if (
        !this.isValueClear &&
        !this.items.some((i) => i.id === this.value.id)
      ) {
        return [...this.items, this.value];
      }

      return this.items;
    },
    isValueClear() {
      if (this.value) {
        if (typeof this.value === "object") {
          return !Object.values(this.value).some((v) => !!v);
        }
      }
      return true;
    },
    hintValue() {
      return this.hint || this.$t("common.autoCompleteAsyncHint");
    },
  },
  watch: {
    search(val) {
      if (!val || !val.trim()) {
        return;
      }

      this.isLoading = true;
      this.request = this.func(val)
        .catch(function (thrown) {
          if (axios.isCancel(thrown)) {
            // obsluga catcha - zeby nie sypało błędami w konsoli o cancelu
          }
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.v-input__slot:before {
  border-color: #fbfbfb !important;
}
::v-deep.v-input__icon--clear > i {
  font-size: 16px !important;
}
::v-deep.v-input__icon--append > i {
  font-size: 16px !important;
}
</style>
