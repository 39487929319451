import Vue from "vue";

const firstLetterToUpper = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const requireComponent = require.context(
  "../components/Basic",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);

  const componentName = firstLetterToUpper(
    fileName.replace(/^\.\/(.*)\.\w+$/, "$1")
  );

  Vue.component(componentName, componentConfig.default || componentConfig);
});
