<template>
  <v-layout id="related-container" v-bind:class="isMobile && 'mobile'">
    <v-flex xs6 pr-3 v-bind:class="isMobile && 'stretched-input'">
      <v-autocomplete
        :placeholder="label"
        v-bind="$attrs"
        :items="items"
        v-model="selectedComplaint"
        :hide-no-data="!search || isLoading"
        :search-input.sync="search"
        :hint="$t('common.autoCompleteAsyncHint')"
        :loading="isLoading"
      >
      </v-autocomplete>
    </v-flex>
    <v-flex
      xs6
      pl-3
      row
      fill-height
      v-bind:class="isMobile && 'stretched-input'"
    >
      <v-chip
        disabled
        color="chipColor"
        text-color="textColor"
        class="elevation-2 justify-center"
        v-for="(complaint, index) in selectedComplaints"
        :key="index"
      >
        <TooltipTruncate
          :title="complaint.text"
          class="pr-2"
          :recalculate="recalculate"
          >{{ complaint.text }}</TooltipTruncate
        >
        <v-icon small color="iconButton" @click="removeComplaint(complaint.id)"
          >fa-times</v-icon
        >
      </v-chip>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "@/plugins/http";
import { mapGetters } from "vuex";

export default {
  props: {
    func: [Array, Function],
    label: String,
    value: [Array],
    cplId: String,
    recalculate: Boolean,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      items: [],
      isLoading: false,
      search: null,
      request: null,
      selectedComplaints: [...this.value],
      selectedComplaint: {},
    };
  },
  computed: {
    ...mapGetters("settings", ["isMobile"]),
  },
  methods: {
    searchFunc(val) {
      const unavailableIds = [
        ...this.selectedComplaints.map((x) => x.id),
        this.cplId,
      ];
      return this.func(val).then(
        (r) =>
          (this.items = r.filter(
            (x) => !unavailableIds.some((y) => x.id === y)
          ))
      );
    },
    removeComplaint(id) {
      this.selectedComplaints = this.selectedComplaints.filter(
        (x) => x.id !== id
      );
      this.$emit("change", this.selectedComplaints);
    },
    clearSelected() {
      this.selectedComplaints = [...this.value];
    },
  },
  watch: {
    search(val) {
      if (!val || !val.trim()) {
        return;
      }

      this.isLoading = true;
      this.request = this.searchFunc(val)
        .catch(function (thrown) {
          if (axios.isCancel(thrown)) {
            //
          }
        })
        .finally(() => (this.isLoading = false));
    },
    selectedComplaint(newVal) {
      if (
        newVal != null &&
        !this.selectedComplaints.some((x) => x.id === newVal.id)
      ) {
        this.selectedComplaints.push(newVal);
        this.$emit("change", this.selectedComplaints);
      }
      this.$nextTick(() => {
        this.selectedComplaint = null;
        this.search = null;
      });
    },
    value() {
      if (this.selectedComplaints !== this.value) {
        this.selectedComplaints = [...this.value];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-chip {
  width: 160px;
}
::v-deep .v-chip__content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .v-chip .vi-icon {
  cursor: pointer !important;
}
#related-container.mobile {
  display: flex;
  flex-direction: column;
}
#related-container.mobile .v-chip {
  width: calc(50% - 8px);
}
</style>
