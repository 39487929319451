export default {
  props: {
    required: { type: Boolean, default: false },
    name: { type: String },
    darken: { type: Boolean, default: false },
  },
  computed: {
    placeholder() {
      if (!this.name) {
        return null;
      }

      return this.required ? `*   ${this.name}` : this.name;
    },
    attrs() {
      let inputStyle = this.darken
        ? { filled: true, singleLine: true }
        : { solo: true };
      return { ...inputStyle, ...this.$attrs };
    },
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
    blurValue(value) {
      this.$emit("blur", value);
    },
  },
};
