<template>
  <div>
    <v-btn v-if="!menuVisible" text icon @click="toggleMenu">
      <IconBox color="iconButton"> stem-hamburger </IconBox>
    </v-btn>

    <v-overlay v-if="menuVisible" :value="true" class="overlay">
      <div class="menu">
        <div class="header">
          <div class="user-name">{{ user.full_name }}</div>
          <div class="actions">
            <div class="logout" @click="signOutOidc">
              <IconBox color="iconButton">stem-logout</IconBox>
              <span class="logout-label">
                {{ $t("common.logout").toUpperCase() }}
              </span>
            </div>
            <v-btn text icon @click="toggleMenu" class="menu-button">
              <IconBox color="iconButton"> stem-hamburger </IconBox>
            </v-btn>
          </div>
        </div>

        <v-list class="items-list">
          <v-list-item
            v-for="(item, index) in menu"
            :key="index"
            :to="{ name: item.name }"
            :exact="item.exact"
            @click="menuVisible = false"
          >
            <v-list-item-icon>
              <IconBox :styles="{ 'font-size': '28px' }" color="iconButton">
                {{ `stem-${item.menuIcon}` }}
              </IconBox>
            </v-list-item-icon>
            <v-list-item-content class="item-name">
              {{ $t(`menu.${item.name}`).toUpperCase() }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "HamburgerMenu",
  data() {
    return {
      menuVisible: false,
    };
  },
  computed: {
    ...mapGetters("oidcStore", ["oidcUser"]),
    user() {
      return this.oidcUser || {};
    },
    ...mapGetters("settings", ["hasRestriction"]),
    routes() {
      var mainRoute = this.$router.options.routes.find(
        (item) => (item.meta || {}).baseRoute == true
      );
      return mainRoute.children
        .filter((item) => (item.meta || {}).menuIcon)
        .map((i) => {
          return { ...i, ...i.meta };
        });
    },
    menu() {
      return this.routes.filter((item) =>
        this.hasRestriction(item.restrictionCode)
      );
    },
  },
  methods: {
    ...mapActions("oidcStore", ["signOutOidc"]),
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
  },
};
</script>

<style scoped lang="scss">
.overlay {
  height: 100vh;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100vh;
  overflow: auto;
  background-color: white;
  border-radius: 0 15px 15px 0;

  .header {
    height: 90px;
    background-color: $header-background;

    .user-name {
      margin-left: 20px;
      padding-top: 10px;
      font-size: 13px;
      color: $text-color;
    }

    .actions {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;

      .logout {
        margin-left: 20px;
        display: flex;
        cursor: pointer;

        .logout-label {
          margin-left: 20px;
          color: $label;
        }
      }

      .menu-button {
        margin-left: auto;
        margin-right: 20px;
      }
    }
  }

  .items-list {
    background-color: white;

    .v-list-item__icon {
      height: 28px;
      width: 28px;
    }

    .item-name {
      color: $text-color;
    }
  }
}
</style>
