<template>
  <v-dialog v-model="isShown" width="800">
    <v-card v-if="profile != null">
      <v-card-title>
        <v-btn @click="isShown = false" icon>
          <IconBox
            :tooltip="$t('common.back')"
            :styles="{ 'font-size': '20px' }"
            color="iconButton"
          >
            stem-arrowBack
          </IconBox>
        </v-btn>
        {{ $t("common.profile").toUpperCase() }}
      </v-card-title>
      <v-card-text>
        <v-layout wrap mx-5>
          <v-flex xs12 class="user-info">
            <span class="subheading font-weight-medium">{{ fullName }}</span>
            <span>({{ profile.login }})</span>
          </v-flex>
          <CategoryField
            :class="[{ mobile: isMobile }, flexOpts2]"
            :label="$t('global.type')"
            :value="profile.type"
          />
          <CategoryField
            :class="[{ mobile: isMobile }, flexOpts2]"
            :label="$t('contacts.department')"
            :value="profile.department"
          />
          <v-flex xs12>
            <v-divider />
          </v-flex>
          <CategoryField
            :class="[{ mobile: isMobile }, flexOpts2]"
            :label="$t('address.city')"
            :value="profile.city.text"
          />
          <CategoryField
            :class="[{ mobile: isMobile }, flexOpts2]"
            :label="$t('address.postCode')"
            :value="profile.postCode"
            >{{ profile.postCode | postCode }}</CategoryField
          >
          <CategoryField
            :class="[{ mobile: isMobile }, flexOpts2]"
            :label="$t('address.street')"
            :value="profile.street"
          />
          <CategoryField
            :class="[{ mobile: isMobile }, flexOpts2]"
            :label="$t('address.houseNo')"
            :value="profile.houseNo"
          />
          <v-flex xs12>
            <v-divider />
          </v-flex>
          <v-flex xs12>
            <v-layout wrap>
              <v-flex xs6>
                <CustomList
                  :values="profile.phones"
                  :label="$t('common.phone')"
                  :additionalLabel="$t('common.additionalPhone')"
                >
                  <template slot-scope="{ item }">
                    {{ item | mobilePhone }}
                  </template>
                </CustomList>
              </v-flex>
              <v-flex xs6>
                <CustomList
                  :values="profile.emails"
                  :label="$t('common.email')"
                  :additionalLabel="$t('common.additionalEmail')"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12>
            <v-divider />
          </v-flex>
          <v-flex xs12>
            <v-layout wrap>
              <div v-for="(rlc, index) in profile.roles" :key="index">
                <CategoryField
                  class="my-3"
                  :label="$t('user.role')"
                  itemText="roleName"
                  :value="profile.roles[index]"
                />
                <div
                  class="read-subcategories-section mt-5"
                  v-for="(item, itemIndex) in rlc.categories"
                  :key="`${index}_${itemIndex}`"
                >
                  <div class="label">
                    {{ $t("user.accessToSubcategoriesForCategory") }}
                    <span class="category-name">{{ item.categoryName }}</span>
                  </div>
                  <CategoryField class="my-1" :value="item.subcategories" />
                </div>
              </div>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import UsersService from "@/services/UsersService.js";
import CategoryField from "@/components/Fields/CategoryField";
import CustomList from "@/components/Shared/CustomList";
import ModalMixin from "@/mixins/modalMixin.js";
import { mapGetters } from "vuex";

export default {
  components: { CategoryField, CustomList },
  mixins: [ModalMixin],
  props: {
    id: { type: String },
  },
  data() {
    return {
      profile: null,
      flexOpts2: "my-3 xs6 category-field",
      flexOpts3: "my-3 xs4 category-field",
    };
  },
  computed: {
    ...mapGetters("settings", ["isMobile"]),
    fullName() {
      return this.profile
        ? `${this.profile.surname} ${this.profile.firstname}`
        : "";
    },
    fullStreet() {
      return this.profile
        ? `${this.profile.street.text} ${this.profile.houseNo}`
        : "";
    },
  },
  watch: {
    isShown(val) {
      if (val) this.fetchUserProfile();
    },
  },
  methods: {
    fetchUserProfile(id) {
      if (!id) {
        UsersService.GetLoggedUserProfile().then((res) => (this.profile = res));
      }
      // IMPLEMENT FETCHING USER PROFILE FOR DESIRED ID
    },
    onClosed() {
      // Required to close modal window.
    },
  },
};
</script>
<style scoped lang="scss">
.v-card__title {
  color: $text-title;
}
.v-card__text {
  padding-top: 10px !important;

  .user-info {
    font-size: 16px;
  }
}
.category-field.mobile {
  word-break: break-word;
}

.read-subcategories-section {
  .label {
    color: $text-color;

    .category-name {
      color: $text-title;
      font-size: 15px;
      font-weight: bold;
    }
  }
}
</style>
