<template>
  <v-combobox
    :placeholder="placeholder"
    v-bind="attrs"
    :value="value"
    :search-input.sync="search"
    :loading="isLoading"
    @input="updateValue($event)"
    :hide-no-data="searchShow"
  >
    <template v-slot:no-data>
      <v-list-item>
        <span class="subheading">{{ $t("global.addOption") }}</span>
        <v-chip class="ml-2" label>{{ search }}</v-chip>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import baseField from "@/utils/BaseField";

export default {
  name: "BaseCombobox",
  mixins: [baseField],
  props: {
    value: { type: [Array, Number, String, Object] },
    func: { type: Function },
  },
  data() {
    return {
      search: null,
      isLoading: false,
    };
  },
  computed: {
    searchShow() {
      if (this.search) {
        if (!this.value) {
          return this.search.trim().length === 0;
        }
        return this.value && this.search.trim().length > 0;
      } else return true;
    },
  },
  watch: {
    search(val) {
      if (!val || !val.trim()) {
        return;
      }
      this.isLoading = true;
      this.func(val).finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.v-input__slot:before {
  border-color: #fbfbfb !important;
}
::v-deep.v-input__icon--clear > i {
  font-size: 16px !important;
}
::v-deep.v-input__icon--append > i {
  font-size: 16px !important;
}
</style>
