export const CPL_WRITE = "CPL_WRITE";
export const CPL_READ = "CPL_READ";
export const CFG_READ = "CFG_READ";
export const CFG_WRITE = "CFG_WRITE";
export const CRM_WRITE = "CRM_WRITE";
export const CRM_READ = "CRM_READ";
export const CRM = "CRM";
export const REPORTS_READ = "REPORTS_READ";
export const DICT_READ = "DICT_READ";
export const DICT_WRITE = "DICT_WRITE";

export const subcategoriesRestrictions = {
  none: "NONE",
  read: "READ",
  readWrite: "READ_WRITE",
};
