import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import App from "./App.vue";
import {
  CPL_WRITE,
  REPORTS_READ,
  CFG_READ,
  CPL_READ,
  CRM,
  CRM_WRITE,
  DICT_READ,
} from "./utils/GlobalRestrictionCodes";

Vue.use(Router);

let importView = (view) => () =>
  import(/* webpackChunkName: "view-[request]" */ `./views/${view}.vue`);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      meta: {
        baseRoute: true,
      },
      component: App,
      children: [
        {
          path: "",
          name: "home",
          redirect: () => {
            return getDefaultRoute();
          },
        },
        {
          path: "/complaints",
          name: "complaints",
          component: importView("ComplaintsList"),
          meta: {
            exact: false,
            menuIcon: "complaint",
            restrictionCode: CPL_READ,
          },
        },
        {
          path: "/complaint/create",
          name: "newComplaint",
          component: importView("NewComplaint"),
          props: true,
          meta: {
            exact: true,
            menuIcon: "complaintPlus",
            restrictionCode: CPL_WRITE,
          },
        },
        {
          path: "/complaints/:id",
          name: "complaintDetails",
          component: importView("ComplaintDetailsProvider"),
          props: true,
          meta: {
            restrictionCode: CPL_READ,
          },
        },
        {
          path: "/customerComplaints",
          name: "customerComplaints",
          component: importView("CustomerComplaintsList"),
          meta: {
            exact: false,
            menuIcon: "customerComplaint",
            restrictionCode: CPL_READ,
          },
        },
        {
          path: "/customerComplaints/cc-view",
          name: "searchCustomerComplaints",
          component: importView("SelectSearchedCustomerComplaints"),
          meta: {
            exact: false,
            restrictionCode: CPL_READ,
          },
        },
        {
          path: "/crm",
          name: "crm",
          redirect: () =>
            store.getters["settings/hasRestriction"](CRM)
              ? { name: "contacts" }
              : { name: "departments" },
          meta: {
            exact: false,
            menuIcon: "personList",
            restrictionCode: CRM,
          },
        },
        {
          path: "/crm/contacts",
          name: "contacts",
          component: importView("ContactsList"),
          meta: {
            restrictionCode: CRM,
          },
        },
        {
          path: "/crm/contacts/create",
          name: "newContact",
          component: importView("NewContact"),
          meta: {
            restrictionCode: CRM_WRITE,
          },
        },
        {
          path: "/crm/contacts/:id",
          name: "contactDetails",
          component: importView("ContactDetailsWrapper"),
          props: true,
          meta: {
            restrictionCode: CRM,
          },
        },
        {
          path: "/reports",
          name: "reports",
          component: importView("ReportsList"),
          meta: {
            exact: true,
            menuIcon: "chart",
            restrictionCode: REPORTS_READ,
          },
        },
        {
          path: "/reports/:id",
          name: "reportDetails",
          component: importView("ReportDetails"),
          props: true,
          meta: {
            restrictionCode: REPORTS_READ,
          },
        },
        {
          path: "/dictionaries",
          name: "dictionaries",
          redirect: () =>
            store.getters["settings/hasRestriction"](DICT_READ)
              ? { name: "categories" }
              : { name: "departments" },
          meta: {
            exact: false,
            menuIcon: "dictionary",
            restrictionCode: DICT_READ,
          },
        },
        {
          path: "/dictionaries/categories",
          name: "categories",
          component: importView("DictionariesList"),
          meta: {
            restrictionCode: DICT_READ,
          },
        },
        {
          path: "/dictionaries/departments",
          name: "departments",
          component: importView("DepartmentsList"),
          meta: {
            restrictionCode: DICT_READ,
          },
        },
        {
          path: "/dictionaries/defaultTags",
          name: "defaultTags",
          component: importView("DefaultTagsList"),
          meta: {
            restrictionCode: DICT_READ,
          },
        },
        {
          path: "/dictionaries/organizations",
          name: "organizations",
          component: importView("OrganizationsDictionaryList"),
          meta: {
            restrictionCode: DICT_READ,
          },
        },
        {
          path: "/settings",
          name: "settings",
          redirect: (to) =>
            store.getters["settings/hasRestriction"](CFG_READ)
              ? { name: "users" }
              : store.getters["settings/hasRestriction"](CFG_READ)
              ? { name: "roles" }
              : to.path,
          meta: {
            exact: false,
            menuIcon: "userSettings",
            restrictionCode: CFG_READ,
          },
        },
        {
          path: "/settings/users",
          name: "users",
          component: importView("UsersList"),
          meta: {
            restrictionCode: CFG_READ,
          },
        },
        {
          path: "/settings/roles",
          name: "roles",
          component: importView("RolesList"),
          meta: {
            restrictionCode: CFG_READ,
          },
        },
        {
          path: "/users/create",
          name: "newUser",
          component: importView("NewUser"),
          meta: {
            restrictionCode: CFG_READ,
          },
        },
      ],
    },
    {
      path: "/oidc-callback", // Needs to match redirect_uri in you oidcSettings
      name: "oidcCallback",
      component: importView("OidcCallback"),
      meta: {
        isOidcCallback: true,
        isPublic: true,
      },
    },
    {
      path: "/silent-refresh-oidc",
      name: "silent-refresh-oidc",
      component: importView("OidcSilentRefresh"),
      meta: {
        isVuexOidcCallback: true,
        isOidcCallback: true,
        isPublic: true,
      },
    },
    {
      path: "/404",
      name: "404",
      component: importView("NotFound"),
      meta: {
        isPublic: true,
      },
    },
    {
      path: "/401",
      name: "401",
      component: importView("UnauthorizedView"),
      meta: {
        isPublic: true,
      },
    },
    {
      path: "*",
      redirect: { name: "404" },
    },
  ],
});

const getDefaultRoute = () => {
  let mainRoute = router.options.routes.find(
    (item) => (item.meta || {}).baseRoute == true
  );
  let paths = mainRoute.children
    .filter((x) => (x.meta || {}).menuIcon)
    .map((x) => ({
      path: x.path,
      available: store.getters["settings/hasRestriction"](
        x.meta.restrictionCode
      ),
    }));
  let path = paths.find((x) => x.available);
  if (path !== undefined) return path.path;
  return null;
};

router.beforeEach((to, from, next) => {
  if ((to.meta || {}).isPublic) {
    next();
  }
  store.dispatch("oidcStore/oidcCheckAccess", to).then((hasAccess) => {
    if (hasAccess) {
      if ((to.meta || {}).restrictionCode) {
        let hasRestriction = store.getters["settings/hasRestriction"](
          to.meta.restrictionCode
        );
        hasRestriction ? next() : next("/401");
      }
    }
  });
});

export default router;
