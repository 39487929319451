<template>
  <v-textarea
    single-line
    :placeholder="placeholder"
    no-resize
    :rows="rows"
    v-bind="attrs"
    :value="value"
    @input="updateValue($event)"
    @blur="blurValue($event.target.value)"
    @keydown="onKeyDown"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-textarea>
</template>

<script>
import baseField from "@/utils/BaseField";

export default {
  name: "BaseTextarea",
  mixins: [baseField],
  props: {
    value: String,
    rows: {
      type: String,
      default: "5",
    },
  },
  methods: {
    onKeyDown(event) {
      this.$emit("keyDown", event);
    },
  },
};
</script>
