<template>
  <v-dialog persistent v-model="isShown" width="340">
    <v-card @keyup.enter="confirm">
      <v-card-title>
        {{ $t("common.changePassword").toUpperCase() }}
      </v-card-title>
      <v-card-text class="card-content">
        <BaseInput
          prepend-inner-icon="stem-lockOpen"
          :label="$t('common.oldPassword')"
          type="password"
          v-model="model.oldPassword"
          :error-messages="errors.oldPassword"
          @input="$v.model.oldPassword.$touch()"
          @blur="$v.model.oldPassword.$touch()"
        />
        <BaseInput
          prepend-inner-icon="stem-lock"
          :label="$t('common.newPassword')"
          type="password"
          v-model="model.newPassword"
          :error-messages="errors.newPassword"
          @input="$v.model.newPassword.$touch()"
          @blur="$v.model.newPassword.$touch()"
        />
        <BaseInput
          prepend-inner-icon="stem-lock"
          :label="$t('common.confirmPassword')"
          type="password"
          v-model="model.confirmPassword"
          :error-messages="errors.confirmPassword"
          @input="$v.model.confirmPassword.$touch()"
          @blur="$v.model.confirmPassword.$touch()"
        />
        <v-alert
          :value="true"
          v-for="err in resErrors"
          :key="err"
          type="error"
          outlined
        >
          {{ err }}
        </v-alert>
      </v-card-text>
      <v-card-actions class="card-buttons">
        <v-btn
          color="roundButton roundButtonText--text"
          rounded
          @click="isShown = false"
          :disabled="isLoading"
        >
          {{ $t("global.cancel") }}
        </v-btn>
        <v-btn
          color="roundButton roundButtonText--text"
          rounded
          @click="confirm"
          :loading="isLoading"
        >
          {{ $t("global.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ModalMixin from "@/mixins/modalMixin.js";
import BaseInput from "@/components/Basic/BaseInput";
import UsersService from "@/services/UsersService";
import { required, minLength, sameAs, not } from "vuelidate/lib/validators";
import validationThings from "../../mixins/validation-things";
import { mapState } from "vuex";
import { EmitSuccess } from "@/eventBus.js";

export default {
  mixins: [ModalMixin, validationThings],
  components: { BaseInput },
  data() {
    return {
      model: this.getNewModel(),
      resErrors: [],
      validatedProperty: "model",
      isLoading: false,
    };
  },
  computed: mapState("oidcStore", ["user"]),
  methods: {
    getNewModel() {
      return {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      };
    },
    async confirm() {
      if (!(await this.validateModelAsync())) return;

      this.isLoading = true;
      UsersService.ChangePassword(this.user.userId, this.model)
        .then((res) => {
          if (res.data) {
            EmitSuccess(this.$t("user.passwordChanged"));
            this.isShown = false;
          } else {
            this.resErrors = res.errors;
          }
        })
        .catch(() => (this.resErrors = [this.$t("login.connection_error")]))
        .finally(() => (this.isLoading = false));
    },
    onClosed() {
      this.model = this.getNewModel();
      this.resErrors = [];
      this.$v.$reset();
    },
  },
  validations: {
    model: {
      oldPassword: { required, noSpaces: (v) => !/\s/.test(v) },
      newPassword: {
        required,
        minLength: minLength(6),
        diffThanOldPassword: not(sameAs("oldPassword")),
        noSpaces: (v) => !/\s/.test(v),
      },
      confirmPassword: { required, sameAsNewPassword: sameAs("newPassword") },
    },
  },
};
</script>

<style scoped lang="scss">
.v-card {
  padding-bottom: 10px;
}
.v-card__title {
  color: $text-title;
}
.card-content {
  display: flex;
  flex-direction: column;
}
.card-buttons {
  display: flex;
  padding-bottom: 10px;

  .v-btn {
    margin-left: auto;
  }
}
</style>
