<template>
  <div>
    <v-menu
      offset-y
      left
      :nudge-width="600"
      transition="slide-y-transition"
      :close-on-content-click="false"
      v-model="openMenu"
    >
      <template #activator="{ on }">
        <v-btn text icon v-on="on">
          <IconBox color="toolbarIcons" :tooltip="$t('common.notifications')">
            stem-bell
          </IconBox>
        </v-btn>
      </template>

      <v-card>
        <v-toolbar color="#f5f5f5" dark>
          <v-toolbar-title class="textColor--text">
            {{ $t("common.notifications") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <div
            v-if="displayOutdatedViewIcon"
            class="outdated-button"
            color="roundButton"
            @click="onRefreshButtonClick"
          >
            <IconBox color="iconButton"> stem-refresh </IconBox>
            <span class="outdated-text">{{ $t("common.showNew") }}</span>
          </div>
          <v-btn
            v-if="notifications.length > 0 && unreadCount > 0"
            text
            icon
            @click="readAllNotifications"
          >
            <IconBox color="iconButton" :tooltip="$t('common.readAll')">
              stem-readAll
            </IconBox>
          </v-btn>
          <v-btn
            v-if="notifications.length > 0"
            text
            icon
            @click="deleteAllNotifications"
          >
            <IconBox color="iconButton" :tooltip="$t('common.deleteAll')">
              stem-deleteAll
            </IconBox>
          </v-btn>
        </v-toolbar>

        <v-list
          v-if="notifications.length > 0"
          class="notification-items overflow-y-auto"
          style="max-height: 350px"
        >
          <div
            v-for="(notification, index) in notifications"
            :key="notification.id"
          >
            <v-list-item
              pa-3
              @click="goToDetails(notification)"
              :class="getNotificationReadClass(notification)"
            >
              <v-list-item-content>
                <v-list-item-title>{{
                  notification.message
                }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ notification.dateCreated | moment("displayDateTime") }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  text
                  icon
                  @click.stop="deleteNotification(notification.id)"
                >
                  <IconBox color="iconButton" :tooltip="$t('common.delete')">
                    stem-delete
                  </IconBox>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider
              v-if="index + 1 < notifications.length"
              :key="`divider_${notification.id}`"
            ></v-divider>
          </div>
        </v-list>
        <v-card-text v-else class="textColor--text">
          {{ $t("common.noNotifications") }}
        </v-card-text>
        <div class="container-bottom">
          <div v-if="areThereMoreNotifications" class="textColor--text">
            <v-btn
              rounded
              small
              color="roundButton roundButtonText--text"
              @click.stop="getMoreNotifications"
            >
              {{ $t("common.morePaged") }}
            </v-btn>
          </div>
          <div class="textColor--text notification-count">
            {{
              $t("common.showed", {
                from: notifications.length,
                to: totalCount,
              })
            }}
            <span class="unread-count">{{
              $t("common.notificationUnreadCount", { count: unreadCount })
            }}</span>
          </div>
        </div>
      </v-card>
    </v-menu>

    <div class="new-notification" v-if="isUnreadNotification" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { BellNotificationStatus } from "./mutations";
import MenuMixin from "@/mixins/menuMixin.js";

const TIMER_TO_FETCH = 10;

export default {
  name: "NotificationsBell",
  mixins: [MenuMixin],
  inject: ["signalRConnected"],
  data() {
    return {
      maxCount: 10,
      fetchNotificationsTimer: null,
      isEnabledTimer: false,
      timerCountdown: null,
      isNewNotificationToFetch: false,
      displayNewNotificationIcon: false,
      displayOutdatedViewIcon: false,
    };
  },
  mounted() {
    this.initiateNotifications(this.maxCount);
  },
  computed: {
    notifications() {
      return this.$store.getters.getNotifications;
    },
    totalCount() {
      return this.$store.getters.getTotalCountNotifications;
    },
    unreadCount() {
      return this.$store.getters.getUnreadCountNofications;
    },
    areThereMoreNotifications() {
      return this.notifications.length < this.totalCount;
    },
    newNotificationId() {
      return this.$store.getters.getNewNotificationId;
    },
    yellowOrRed() {
      return this.signalRConnected() ? "badgeBackground" : "red";
    },
    isUnreadNotification() {
      return this.unreadCount !== 0;
    },
  },
  methods: {
    ...mapActions([
      "markNotificationAsRead",
      "initiateNotifications",
      "deleteNotifications",
      "deleteNotification",
      "readNotifications",
      "refreshNotifications",
    ]),
    readAllNotifications() {
      this.readNotifications().then((success) => {
        if (success) {
          this.refreshNotifications(this.maxCount, this.newNotificationId);
        }
      });
    },
    deleteAllNotifications() {
      this.deleteNotifications().then((success) => {
        if (success) {
          this.refreshNotifications(this.maxCount, this.newNotificationId);
        }
      });
    },
    async goToDetails(notification) {
      await this.markNotificationAsRead(notification.id);

      this.openMenu = false;
      if (this.$route.params.id !== notification.cplId) {
        this.$router.push({
          name: "complaintDetails",
          params: { id: notification.cplId },
        });
      }
    },
    getNotificationReadClass(notification) {
      return notification.status === BellNotificationStatus.READ
        ? "status-read"
        : "";
    },
    getMoreNotifications() {
      this.initiateNotifications(this.maxCount);
    },
    onRefreshButtonClick() {
      this.displayOutdatedViewIcon = false;
      clearTimeout(this.fetchNotificationsTimer);
      this.refreshNotifications(this.maxCount, this.newNotificationId);
    },
  },
  watch: {
    openMenu(isOpen) {
      if (isOpen) {
        /** Panel jest otwierany i jest nowe powiadomienie. */
        if (this.displayNewNotificationIcon) {
          clearTimeout(this.fetchNotificationsTimer);
          this.timerCountdown = 0;
        }
      } else {
        if (this.displayOutdatedViewIcon) {
          /** Panel jest zamykany i były nowe powiadomienia. */
          this.displayOutdatedViewIcon = false;
          this.refreshNotifications(this.maxCount, this.newNotificationId);
        } else if (
          /** Panel jest zamykany i usunięto powiadomienia / pobrano kolejne powiadomienia. */
          (this.notifications.length < 10 && this.areThereMoreNotifications) ||
          this.notifications.length > 10
        ) {
          this.refreshNotifications(this.maxCount);
        }
      }
    },
    timerCountdown(value) {
      if (this.isEnabledTimer) {
        this.fetchNotificationsTimer = setTimeout(() => {
          if (value > 0) {
            this.timerCountdown--;
          } else if (this.isNewNotificationToFetch) {
            this.isEnabledTimer = false;
            clearTimeout(this.fetchNotificationsTimer);
            this.isNewNotificationToFetch = false;
            this.refreshNotifications(this.maxCount, this.newNotificationId);
          }
        }, 1000);
      }
    },
    newNotificationId(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.openMenu) {
          this.displayOutdatedViewIcon = true;
        } else {
          clearTimeout(this.fetchNotificationsTimer);
          this.isEnabledTimer = true;
          this.timerCountdown = TIMER_TO_FETCH;
          this.isNewNotificationToFetch = true;
          this.displayNewNotificationIcon = true;
        }
      }
    },
    unreadCount(newValue) {
      if (newValue !== 0) {
        this.displayNewNotificationIcon = true;
      } else {
        this.displayNewNotificationIcon = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.new-notification {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: relative;
  bottom: 30px;
  left: 35px;
  margin-top: -10px;
  animation: blink 2.5s infinite;
}

@keyframes blink {
  0% {
    background-color: transparent;
  }

  50% {
    background-color: $notification-blink;
  }
}

.outdated-button {
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;
}

.outdated-text {
  color: $text-color;
}

.container-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  box-shadow: 0 5px 5px 8px rgba(0, 0, 0, 0.2);

  .notification-count {
    margin-left: auto;

    .unread-count {
      color: $label;
    }
  }
}

.theme--light {
  .notification-items {
    .status-read {
      color: lightgrey;
    }
  }
}
</style>
