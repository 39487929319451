export default {
  data() {
    return {
      openMenu: false,
    };
  },
  mounted() {
    window.addEventListener("keydown", this.close);
  },
  destroyed() {
    window.removeEventListener("keydown", this.close);
  },
  methods: {
    close(e) {
      if (e.keyCode === 27) this.openMenu = false;
    },
  },
};
