import moment from "moment";

function areThereAnyMoreNotifications(state) {
  return state.notifications.isNextPage;
}

function getNotifications(state) {
  const notifications = [...state.notifications.data];

  const notificationsId = notifications.map((notification) => notification.id);
  const filtered = notifications.filter((value, index) => {
    return !notificationsId.includes(value.id, index + 1);
  });

  filtered.sort((a, b) =>
    moment(a.dateCreated).isBefore(b.dateCreated) ? 1 : -1
  );
  return filtered;
}

function getTotalCountNotifications(state) {
  return state.notifications.totalCount;
}

function getUnreadCountNofications(state) {
  return state.notifications.unreadCount;
}

function getNewNotificationId(state) {
  return state.notifications.newNotificationId;
}

export const notificationGetters = {
  areThereAnyMoreNotifications,
  getNotifications,
  getTotalCountNotifications,
  getUnreadCountNofications,
  getNewNotificationId,
};
