export default {
  props: {
    id: Number,
    name: String,
    required: Boolean,
    default: Object,
    errorMessage: String,
  },
  data() {
    return {
      value: null,
    };
  },

  computed: {
    placeholder() {
      return this.required ? `*   ${this.name}` : this.name;
    },
  },
  methods: {
    checkIsFieldValid() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
  },
};
