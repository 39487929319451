<template>
  <TooltipComponent :title="tooltip">
    <div class="icon-wrapper">
      <div v-if="name !== undefined">
        <svg class="icon" :width="width" :height="height" :style="iconStyle">
          <use v-bind="{ href: 'icons.svg#' + name }" />
        </svg>
      </div>
      <div v-else>
        <v-icon :style="iconStyle" v-bind="$attrs">
          <slot></slot>
        </v-icon>
      </div>
    </div>
  </TooltipComponent>
</template>

<script>
export default {
  props: {
    name: { type: String },
    width: {
      type: [Number, String],
      default: 25,
    },
    height: {
      type: [Number, String],
      default: 25,
    },
    color: {
      type: String,
    },
    tooltip: {
      type: String,
    },
    styles: {
      type: [Object],
    },
  },
  computed: {
    iconStyle() {
      // If color is not set, it uses theme--light.v-icon color, which can not be change in component's styles. To allow changes in stlyes, it must be set to 'inherit'.
      let style = {
        color: this.$vuetify.theme.themes.light[this.color] || "inherit",
      };

      if (typeof this.styles === "object") {
        style = { ...style, ...this.styles };
      }

      return style;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-wrapper {
  display: inline-flex;
  align-items: center;
}
.icon {
  stroke: currentColor;
  stroke-width: 0.1;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: currentColor;
}
</style>
