export const NotificationStatus = Object.freeze({
  SUCCESS: 0,
  ERROR: 1,
  WARNING: 2,
  INFO: 3,
  0: "SUCCESS",
  1: "ERROR",
  2: "WARNING",
  3: "INFO",
});

export const CudOperationType = Object.freeze({
  CREATE: 0,
  UPDATE: 1,
  DELETE: 2,
  0: "CREATE",
  1: "UPDATE",
  2: "DELETE",
});

export const ModelType = Object.freeze({
  COMPLAINT: 0,
  SWITCHOFF: 1,
  CRMCUSTOMER: 2,
  USER: 3,
  0: "COMPLAINT",
  1: "SWITCHOFF",
  2: "CRMCUSTOMER",
  3: "USER",
});
