import Vue from "vue";

Vue.filter("join", function (value) {
  if (value && typeof value !== "object") {
    return value;
  } else if (Array.isArray(value)) {
    return value.join(", ");
  }
  return "";
});

Vue.filter("bool", (value) =>
  value !== undefined && !!JSON.parse(value + "".toLowerCase()) ? "Tak" : "Nie"
);

Vue.filter("active", (value) =>
  value !== undefined && !!JSON.parse(value + "".toLowerCase())
    ? "Aktywny"
    : "Nieaktywny"
);

Vue.filter("postCode", (value) =>
  typeof value === "string" && value.length >= 5
    ? `${value.substr(0, 2)}-${value.substr(2, 3)}`
    : ""
);

Vue.filter("mobilePhone", (value) =>
  typeof value === "string" && value.length >= 9
    ? `${value.substr(0, 3)}-${value.substr(3, 3)}-${value.substr(6, 3)}`
    : ""
);
