export const NotificationTypes = {
  COMPLAINT_ADDED: 0,
  COMMENT_ADDED: 1,
  COMPLAINT_EXPIRED: 2,
  COMPLAINT_CHANGED: 3,
  COMMENT_FAILED: 4,
  ATTCHMENTS_ADDED: 5,
  ATTCHMENTS_FAILED: 6,
  COMPLAINT_CHAINED: 7,
  COMPLAINT_CHAIN_FAILED: 8,
  COMPLAINT_CLOSED: 9,
};
