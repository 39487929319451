<template>
  <div>
    <div class="field-label">
      {{ placeholder }}
    </div>
    <v-radio-group
      :value="value"
      row
      :error-messages="errors.value"
      @change="
        (newValue) => {
          onChange(newValue), $v.$touch();
        }
      "
      @blur="$v.$touch()"
    >
      <v-radio
        :label="labelTrue || $t('common.yes')"
        :value="true"
        color="fields"
        :ripple="false"
      ></v-radio>
      <v-radio
        :label="labelFalse || $t('common.no')"
        :value="false"
        color="fields"
        :ripple="false"
      ></v-radio>
    </v-radio-group>
  </div>
</template>
<script>
import abstractField from "@/utils/AbstractField";
import validationThings from "@/mixins/validation-things";
export default {
  name: "BoolField",
  mixins: [abstractField, validationThings],
  props: {
    paramValue: [Boolean, String],
    labelTrue: {
      type: String,
    },
    labelFalse: {
      type: String,
    },
  },
  methods: {
    onChange(newValue) {
      this.$emit("onChange", {
        id: this.id,
        value: newValue,
      });
    },
  },
  mounted() {
    if (this.paramValue !== undefined) {
      if (typeof this.paramValue === "boolean") this.value = this.paramValue;
      else if (typeof this.paramValue === "string") {
        this.value = this.paramValue === "true" ? true : false;
      }
    }
  },
  validations: {
    value: {
      required: (value, vm) => {
        return vm.required && (value === undefined || value === null)
          ? false
          : true;
      },
    },
  },
  watch: {
    paramValue(newVal) {
      if (newVal !== this.value) this.value = newVal;
    },
  },
};
</script>

<style scoped lang="scss">
.field-label {
  font-size: 15px;
}

::v-deep .v-input--selection-controls.v-input {
  margin: 0;
}
</style>
