<template>
  <div>
    <UserProfileModal
      :id="user.userId"
      v-model="isProfileModalShown"
    ></UserProfileModal>
    <ChangePasswordModal v-model="isChangePasswordModalShown" />
    <ModalWindow ref="messageModal" />

    <v-menu transition="slide-y-transition" bottom left offset-y>
      <template v-slot:activator="{ on }">
        <v-btn large text icon v-on="on">
          <IconBox color="toolbarIcons" large>stem-avatar</IconBox>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click.stop="isProfileModalShown = true">
          <v-list-item-avatar>
            <IconBox color="iconButton">stem-person</IconBox>
          </v-list-item-avatar>
          <v-list-item-content xs-align-right>
            <v-list-item-title>{{ $t("common.profile") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click.stop="onChangePasswordItemClick">
          <v-list-item-avatar>
            <IconBox color="iconButton">stem-lockOpen</IconBox>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("common.changePassword")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="signOutOidc">
          <v-list-item-avatar>
            <IconBox color="iconButton">stem-logout</IconBox>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ $t("common.logout") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import UserProfileModal from "@/components/Users/UserProfileModal";
import ChangePasswordModal from "@/components/Users/ChangePasswordModal";
import ModalWindow from "@/components/Shared/ModalWindow";

export default {
  components: { UserProfileModal, ChangePasswordModal, ModalWindow },
  data() {
    return {
      isProfileModalShown: false,
      isChangePasswordModalShown: false,
    };
  },
  computed: mapState("oidcStore", ["user"]),
  methods: {
    ...mapActions("oidcStore", ["signOutOidc"]),
    onChangePasswordItemClick() {
      if (this.user.WindowsProvider && this.user.isLocalUser === "False") {
        this.$refs.messageModal.open(
          this.$t("common.changePassword"),
          this.$t("login.changePasswordIsUnavailable"),
          {
            showOnlyCloseButton: true,
            cancelButtonText: this.$t("common.close"),
            width: 450,
          }
        );
      } else {
        this.isChangePasswordModalShown = true;
      }
    },
  },
};
</script>
