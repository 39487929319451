import { render, staticRenderFns } from "./TooltipTruncate.vue?vue&type=template&id=ee19e5fc&scoped=true&"
import script from "./TooltipTruncate.vue?vue&type=script&lang=js&"
export * from "./TooltipTruncate.vue?vue&type=script&lang=js&"
import style0 from "./TooltipTruncate.vue?vue&type=style&index=0&id=ee19e5fc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee19e5fc",
  null
  
)

export default component.exports