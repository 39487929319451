// Pamiętajcie dopisać teksty dla używanych przez was atrybutów walidacji w pl.json/rules
// Jezeli validations() ma plaska strukture to dziala automatycznie dla wszystkich zdefiniowanych (np. dynamic fields)
// Jezeli validations() ma obiekt, ktory zawiera propertasy (np. newComplaint) to w zadanym modelu nalezy ustawic validatedProperty jako nazwa obiketu ktory bedzie validowany(wszystkie jego propsy)
// Jeżeli obiekt do walidacji zawiera skomplikowana strukure (nie tylko plaskie propsy, ale rowniez jakies listy albo zagniezdzenia) to nalezy zdefiniowac strukture:
//      customValidationDefinition => [{name: "emails", definition: fcn}, {name: "phones", definition: fcn}] (wzorować się na /contacts/create)

export default {
  computed: {
    errors() {
      const errs = {};

      var vm = this;
      var validationObj = vm.validatedProperty
        ? vm.$v[vm.validatedProperty]
        : vm.$v;

      Object.keys(validationObj.$params).forEach((val) => {
        if (!errs[val]) {
          errs[val] = [];
        }

        var param = validationObj[val];

        if (param["$dirty"]) {
          //jesli jest zdefiniowana wlasna definicja walidacji to jej uzyj
          if (
            !!vm.customValidationDefinition &&
            Array.isArray(vm.customValidationDefinition) &&
            vm.customValidationDefinition.some((el) => {
              return el.name === val;
            })
          ) {
            var definition = vm.customValidationDefinition.find((el) => {
              return el.name === val;
            }).definition;
            if (typeof definition === "function") {
              errs[val].push(...definition(param, vm.$t(`rules`)));
            }
          } else {
            Object.keys(param.$params).forEach((key) => {
              if (!param[key]) {
                errs[val].push(vm.$t(`rules.${key}`));
              }
            });
          }
        }
      });

      return errs;
    },
  },
  methods: {
    validateModelAsync() {
      this.$v.$touch();

      return new Promise((resolve) => {
        if (!this.$v.$pending) {
          return resolve(this._validateModel());
        }

        const unwatch = this.$watch("$v.$pending", () => {
          unwatch();
          return resolve(this._validateModel());
        });
      });
    },
    _validateModel() {
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
        return false;
      } else {
        this.submitStatus = "PENDING";
        return true;
      }
    },
  },
};
