<script>
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { NotificationStatus } from "../models/notificationParams";
import { NotificationTypes } from "@/models/notificationTypes";
import { mapActions, mapGetters } from "vuex";
import { RestrictionModifyType } from "@/models/restrictions";
import { EmitMessage, EmitWarning } from "../eventBus";

const connectionUrl = "/signalRHub";
function createHubConnection(token, localId) {
  return new HubConnectionBuilder()
    .withUrl(connectionUrl + `?LocalId=${localId}`, {
      accessTokenFactory: () => token,
    })
    .configureLogging(LogLevel.Error)
    .build();
}

export default {
  name: "SignalRProvider",
  async created() {
    this.connectionHub = createHubConnection(
      this.accessToken,
      this.getOrSetLocalId()
    );
    this.connectionHub.on("receiveCommandCreated", this.onNotification);
    this.connectionHub.on("receiveCommandResult", this.onCudNotification);
    this.connectionHub.on(
      "listenForNotifications",
      this.onListenForNotifications
    );
    this.connectionHub.on("receiveCommandLogout", this.onNotificationLogout);
    this.connectionHub.on(
      "receiveRoleOrRestrictionChange",
      this.onRestrictionChange
    );

    await this.start();
  },
  beforeDestroy() {
    this.connectionHub.off("receiveCommandCreated");
    this.connectionHub.off("receiveCommandResult");
    this.connectionHub.off("listenForNotifications");
    this.connectionHub.off("receiveCommandLogout");
  },
  data() {
    return {
      connectionHub: null,
      connected: false,
    };
  },
  provide() {
    return { signalRConnected: () => this.connected };
  },
  computed: {
    ...mapGetters({ accessToken: "oidcStore/oidcAccessToken" }),
  },
  methods: {
    ...mapActions(["incomingNewNotification"]),
    ...mapActions("oidcStore", ["signOutOidc"]),
    ...mapActions({
      setIsComplaintCommentsOutdated: "setIsComplaintCommentsOutdated",
    }),
    async start() {
      this.connectionHub.onclose(async () => {
        await this._tryToConnect();
      });

      await this._tryToConnect();
    },
    async _tryToConnect() {
      try {
        await this.connectionHub.start();
        this.connected = true;
      } catch (err) {
        this.connected = false;
        setTimeout(() => this._tryToConnect(), 5000);
      }
    },
    onNotification(notifi) {
      EmitMessage(notifi.message, notifi.status);
    },
    onNotificationLogout() {
      this.signOutOidc();
    },
    onCudNotification(notifi) {
      if (notifi.status !== NotificationStatus.INFO) {
        EmitMessage(notifi.message, notifi.status);
      }

      if (notifi.notifiType === NotificationTypes.COMMENT_ADDED) {
        this.setComplaintCommentsOutdated(notifi.payload.cplId);
      }
    },
    onListenForNotifications(notifications) {
      if (Array.isArray(notifications) && notifications.length > 0) {
        const commentNotification = notifications.find(
          (item) => item.notifiType === NotificationTypes.COMMENT_ADDED
        );

        if (commentNotification) {
          this.setComplaintCommentsOutdated(commentNotification.cplId);
        }
      }
      this.incomingNewNotification(notifications);
    },
    getOrSetLocalId() {
      let localId = window.localStorage.localId;

      if (!localId) {
        localId = window.localStorage.localId = Date.now();
      }

      return localId;
    },
    setComplaintCommentsOutdated(complaintIdFromNotification) {
      if (!complaintIdFromNotification) return;

      const currentRoutePath = this.$router.history
        ? this.$router.history.current
        : {};

      if (
        currentRoutePath.name === "complaintDetails" &&
        (currentRoutePath.params || {}).id === complaintIdFromNotification
      ) {
        this.setIsComplaintCommentsOutdated(true);
      }
    },
    onRestrictionChange(payload) {
      if (payload.type === RestrictionModifyType.Info) {
        EmitWarning(
          this.$t("restrictions.administratorChangedRestrictions"),
          -1 // don't close snackbar
        );
      } else if (payload.type === RestrictionModifyType.Refresh) {
        this.$emit("displayLogoutPopup");
      }
    },
  },
  render() {
    return this.$scopedSlots.default();
  },
};
</script>
