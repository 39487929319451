<template>
  <v-dialog
    v-model="showModal"
    :max-width="options.width"
    :persistent="options.persistent"
  >
    <v-card>
      <v-card-text>
        <v-card-title>
          <span class="header">{{ title }}</span>
        </v-card-title>
        <div class="message">{{ message }}</div>
      </v-card-text>
      <v-card-actions>
        <div class="buttons-section">
          <v-btn
            v-if="!options.showOnlyCloseButton"
            class="button"
            color="roundButton roundButtonText--text"
            rounded
            @click.stop="onConfirm"
          >
            {{ options.confirmButtonText }}
          </v-btn>
          <v-btn
            class="button"
            color="roundButton roundButtonText--text"
            rounded
            @click.stop="onCancel"
          >
            {{ options.cancelButtonText }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import i18n from "@/plugins/i18n";

export default {
  name: "ModalWindow",
  data() {
    return {
      modal: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        cancelButtonText: i18n.t("global.cancel"),
        confirmButtonText: i18n.t("global.confirm"),
        width: 390,
        showOnlyCloseButton: false,
        persistent: false,
      },
    };
  },
  computed: {
    showModal: {
      get() {
        return this.modal;
      },
      set(value) {
        this.modal = value;
        if (value === false) {
          this.onCancel();
        }
      },
    },
  },
  methods: {
    open(title, message, options) {
      this.modal = true;
      this.title = title;
      this.message = message;
      if (options) {
        this.options = { ...this.options, ...options };
      }

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    onCancel() {
      this.resolve(false);
      this.modal = false;
    },
    onConfirm() {
      this.resolve(true);
      this.modal = false;
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  color: $text-title;
}

.message {
  padding: 10px 0;
  font-size: 16px;
  white-space: pre-wrap;
}

.buttons-section {
  margin-left: auto;
  margin-bottom: 15px;

  .button {
    margin-left: 10px;
  }
}
</style>
