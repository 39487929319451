const CategoryFieldTypes = Object.freeze({
  SELECT: "selectField",
  TEXT: "textField",
  TEXTAREA: "textAreaField",
  DATEPICKER: "datePickerField",
  TIMEPICKER: "timePickerField",
  BOOL: "boolField",
  SELECTTAGS: "selectTagsField",
  AUTOCOMPLETE: "autocompleteField",
  AUTOCOMPLETEASYNC: "autocompleteFieldAsync",
  COMPLAINTASYNC: "complaintFieldAsync",
});

export default CategoryFieldTypes;
