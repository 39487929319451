<template>
  <v-text-field
    :placeholder="placeholder"
    v-bind="attrs"
    :value="value"
    @input="updateValue($event)"
    @blur="blurValue($event.target.value)"
    @keyup.enter="$event.target.blur()"
    @keydown="onKeyDown"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-text-field>
</template>

<script>
import baseField from "@/utils/BaseField";

export default {
  name: "BaseInput",
  mixins: [baseField],
  props: {
    value: String,
  },
  methods: {
    onKeyDown(event) {
      this.$emit("keyDown", event);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.v-input__slot:before {
  border-color: #fbfbfb !important;
}
</style>
